import { Button, Box, Typography, FormControl, TextField, MenuItem, Tooltip, Autocomplete } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { BoxContainer, BoxHeader, ButtonsBox, FieldBox, FormBox } from './style';
import FilterDrawer from './Drawer';
import { toast } from 'react-toastify';
import CancelDialog from './CancelDialog';

const FormBaixa = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    // ============================= STATES =============================
    const [baixa, setBaixa] = useState({
        itemId: '',
        unidade: '',
        data: new Date().toISOString().split('T')[0],
        quantidade: '',
        almoxarifado: '',
        localizacao: '',
        observacao: ''
    });
    const [open, setOpen] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [dependency, setDependency] = useState({ itens: [], almoxarifados: [], localizacoes: [], unidades: [], saidas: [] });
    const [filtredLocations, setFiltredLocations] = useState([]);
    // ============================= FUNÇÕES =============================
    const handleChangeLocations = (location) => {
        const filtredData = dependency.localizacoes.filter((i) => i.id_almoxarifado === location);
        setFiltredLocations(filtredData);
    };

    const handleChangeItem = (newValue) => {
        const selectedItem = dependency.itens.find((item) => item.id === newValue.id);
        handleChangeLocations(selectedItem?.almoxarifado?.id);
        setBaixa({
            ...baixa,
            itemId: selectedItem.id,
            unidade: selectedItem.unidade_medida.id,
            almoxarifado: selectedItem.almoxarifado.id,
            localizacao: selectedItem.localizacao.id
        });
    };

    const handleChangeField = (field, value) => {
        setBaixa({ ...baixa, [field]: value });
    };

    const resetState = () => {
        setBaixa({
            itemId: '',
            unidade: '',
            data: new Date().toISOString().split('T')[0],
            quantidade: '',
            almoxarifado: '',
            localizacao: '',
            observacao: ''
        });
    };

    const handleCheckDialog = (baixa) => {
        if (baixa.id) {
            setIsDelete(true);
            setCancelDialogOpen(true);
        } else {
            setCancelDialogOpen(true);
        }
    };

    const handleCloseDialog = () => {
        setCancelDialogOpen(false);
    };

    const handleCheckValues = (params) => {
        // tirar os campos não obrigatórios
        // ou montar objeto apenas com os campos necessários com os nomes que precisarem ser mudados
        // ex: itemId => item ou selectedItem => item
        const filtredObject = {
            item: params.itemId,
            unidade: params.unidade,
            data: params.data,
            quantidade: params.quantidade,
            almoxarifado: params.almoxarifado,
            localizacao: params.localizacao
        };

        let errorArr = [];
        Object.keys(filtredObject).forEach((field) => {
            if (filtredObject[field] === '' || filtredObject[field] === null) {
                let key = field.replace(field[0], field[0].toUpperCase());
                toast.error(`O campo ${key} deve ser informado`);
                errorArr.push(field);
            }
            return errorArr;
        });

        if (errorArr.length > 0) {
            return false;
        } else {
            return true;
        }
    };

    // === SUBMITS / API CALLS ===

    const getDependency = async () => {
        try {
            const [item, unidade, almoxarifado, localizacao, baixas] = await Promise.all([
                api.getAllItens(),
                api.getAllUnit(),
                api.getAllAlmoxarifado(),
                api.getAllLocalizacao(),
                api.getAllSaidas()
            ]);

            setDependency({
                itens: item.data.itens,
                almoxarifados: almoxarifado.data.almoxarifados,
                localizacoes: localizacao.data.localizacao,
                unidades: unidade.data.unidades,
                saidas: baixas.data
            });
        } catch (error) {
            toast.error(`Erro ao bucar dados: ${error.message}`);
        }
    };

    const handelConfirmDialog = async () => {
        if (isDelete) {
            try {
                await api.deleteSaida(baixa.id);
                getDependency();
                resetState();
                setCancelDialogOpen(false);
                toast.success('Saida excluída com sucesso');
            } catch (error) {
                toast.error(`Erro: ${error.response.data.message}`);
                setCancelDialogOpen(false);
            }
        } else {
            resetState();
            setCancelDialogOpen(false);
        }
    };

    const handleCreateBaixa = async (e, baixa) => {
        try {
            e.preventDefault();
            // caso tenha baixa.id é update
            // baixa.id === id da saida no banco
            // baixa.itemId === id do item da saida
            if (handleCheckValues(baixa)) {
                if (baixa.id) {
                    await api.updateSaida(baixa.id, baixa);
                    resetState();
                    getDependency();
                    toast.success('Baixa atualizada com sucesso');
                } else {
                    await api.createSaida(baixa);
                    resetState();
                    getDependency();
                    toast.success('Baixa cadastrada com sucesso');
                }
            }
        } catch (error) {
            console.log(error.message);
            toast.error(`Erro: ${error.response.data.message}`);
        }
    };

    useEffect(() => {
        getDependency();
    }, []);

    return (
        <BoxContainer>
            {/* COMPONENT */}
            <FilterDrawer
                open={open}
                setOpen={setOpen}
                itens={dependency.itens}
                saidas={dependency.saidas}
                setBaixa={setBaixa}
                handleChangeLocations={handleChangeLocations}
            />
            {/* DIALOG CANCELAR / EXCLUIR  */}
            <CancelDialog
                open={cancelDialogOpen}
                onClose={handleCloseDialog}
                handelConfirmDialog={handelConfirmDialog}
                isDelete={isDelete}
            />
            {/* FORM */}
            <BoxHeader>
                <Typography sx={{ fontSize: '1.125rem', fontWeight: '500', color: '#121926' }}>Inserir nova baixa</Typography>
                <Tooltip title="Filtrar baixa">
                    <Button variant="contained" color="warning" onClick={() => setOpen(true)}>
                        <SearchIcon />
                    </Button>
                </Tooltip>
            </BoxHeader>
            <FormBox>
                <FieldBox>
                    <FormControl fullWidth>
                        <Autocomplete
                            options={dependency.itens}
                            getOptionLabel={(item) => `${item.id} - ${item.descricao}`}
                            value={dependency.itens.find((item) => item.id === baixa.itemId) || null}
                            onChange={(e, newValue) => handleChangeItem(newValue)}
                            renderInput={(params) => <TextField {...params} label="Item" />}
                        />
                        {/* <TextField select label="Item" value={baixa.itemId} onChange={(e) => handleChangeItem(e)}>
                            {dependency.itens.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.descricao}
                                </MenuItem>
                            ))}
                        </TextField> */}
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            select
                            label="Unidade"
                            disabled
                            value={baixa.unidade}
                            onChange={(e) => handleChangeField('unidade', e.target.value)}
                        >
                            {dependency.unidades.map((unidade) => (
                                <MenuItem key={unidade.id} value={unidade.id}>
                                    {unidade.descricao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </FieldBox>
                <FieldBox>
                    <FormControl fullWidth>
                        <TextField type="date" label="Data" value={baixa.data} disabled InputLabelProps={{ shrink: true }} />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            type="number"
                            label="Quantidade"
                            value={baixa.quantidade}
                            onChange={(e) => handleChangeField('quantidade', e.target.value)}
                        />
                    </FormControl>
                </FieldBox>
                <FieldBox>
                    <FormControl fullWidth>
                        <TextField
                            select
                            label="Almoxarifado"
                            value={baixa.almoxarifado}
                            onChange={(e) => {
                                handleChangeField('almoxarifado', e.target.value), handleChangeLocations(e.target.value);
                            }}
                        >
                            {dependency.almoxarifados.map((almoxarifado) => (
                                <MenuItem key={almoxarifado.id} value={almoxarifado.id}>
                                    {almoxarifado.descricao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            select
                            label="Localização"
                            value={baixa.localizacao}
                            onChange={(e) => handleChangeField('localizacao', e.target.value)}
                        >
                            {filtredLocations.map((localizacao) => (
                                <MenuItem key={localizacao.id} value={localizacao.id}>
                                    {localizacao.descricao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </FieldBox>
                <FieldBox>
                    <FormControl fullWidth>
                        <TextField
                            label="Observação"
                            multiline
                            minRows={5}
                            value={baixa.observacao}
                            onChange={(e) => handleChangeField('observacao', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>
                </FieldBox>
            </FormBox>
            <ButtonsBox>
                <Tooltip title="Salvar baixa">
                    <Button variant="contained" color="success" onClick={(e) => handleCreateBaixa(e, baixa)}>
                        <SaveIcon />
                    </Button>
                </Tooltip>
                <Tooltip title={baixa.id ? 'Excluir baixa' : 'Cancelar cadastro de baixa'}>
                    <span>
                        <Button variant="contained" color="error" disabled={baixa.itemId === ''} onClick={() => handleCheckDialog(baixa)}>
                            <DeleteIcon />
                        </Button>
                    </span>
                </Tooltip>
            </ButtonsBox>
        </BoxContainer>
    );
};

export default FormBaixa;
