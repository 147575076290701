import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import formater from 'utils/DateDDMMYYY';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { toast } from 'react-toastify';
import { DeleteRowModal } from './components/DeleteRowModal';
import AnalyseModal from './components/AnalyseModal';

const FormularioLiberacao = () => {
    const { id } = useParams();
    const api = useApi();
    const navigate = useNavigate();

    const [tableRows, setTableRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openAnalysesModal, setOpenAnalysesModal] = useState(false);

    async function getPrimeiroNivel() {
        setLoading(true);
        try {
            const { data } = await api.getNv1Separacao(id);
            const tableRow = data.map((row) => {
                return {
                    id: row.id,
                    data: formater(row.data_execucao),
                    status: row.status,
                    rest: row.separacao_itens
                };
            });
            setTableRows(tableRow);
            setLoading(false);
            console.log('🚀 ~ getPrimeiroNivel ~ data:', data);
        } catch (error) {
            console.log('🚀 ~ getPrimeiroNivel ~ error:', error);
        }
    }

    useEffect(() => {
        getPrimeiroNivel();
    }, []);

    //itens do datagrid

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'data', headerName: 'Data', flex: 1 },
        { field: 'status', headerName: 'Status Lib', flex: 1 },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <div>
                        <Tooltip
                            title="Análisar"
                            onClick={() => {
                                navigate(`/main/liberacao/finalizancao/${params.row.id}`);
                            }}
                        >
                            <IconButton>
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }
        }
    ];

    // codgo referente ao delete

    const [currentRowId, setCurrentRowId] = useState('');

    async function handleDeleteSeparacao(rowId) {
        try {
            await api.deleteSeparacao(rowId);
            getPrimeiroNivel();
            toast.success('Separação deletada com sucesso');
            setOpenDeleteModal(false);
        } catch (error) {
            console.log('🚀 ~ handleDeleteSeparacao ~ error:', error);
        }
    }

    return (
        <MainCard title="Separações Realizadas" sx={{ position: 'relative' }}>
            <Grid container spacing={gridSpacing}>
                <AnalyseModal open={openAnalysesModal} setOpen={setOpenAnalysesModal} currentId={currentRowId} />

                <Box sx={{ marginTop: '10px', width: '100%' }}>
                    {loading && <p style={{ textAlign: 'center' }}>Carregando...</p>}
                    {!loading && tableRows.length === 0 && <p style={{ textAlign: 'center' }}>Nenhuma separação realizada</p>}
                    {!loading && tableRows.length > 0 && <DataGrid columns={columns} rows={tableRows} hideFooter />}
                </Box>
            </Grid>
        </MainCard>
    );
};

export default FormularioLiberacao;
