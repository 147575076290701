import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import formater from 'utils/DateDDMMYYY';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { toast } from 'react-toastify';
import { DeleteRowModal } from './components/DeleteRowModal';
import AnalyseModal from './components/AnalyseModal';

const PrimeiroNivel = () => {
    const { id } = useParams();
    const api = useApi();
    const navigate = useNavigate();

    const [tableRows, setTableRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openAnalysesModal, setOpenAnalysesModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [solicitacao, setSolicitacao] = useState(null);

    async function getSolicitacao(solicitacaoID) {
        try {
            const { data } = await api.getSolicitacaoById(solicitacaoID);
            setSolicitacao(data?.data);
            console.log(data);
        } catch (error) {
            toast.error('Erro ao buscar registro da solicitação');
        }
    }

    async function getPrimeiroNivel() {
        setLoading(true);
        try {
            const { data } = await api.getNv1Separacao(id);
            const tableRow = data.map((row) => {
                return {
                    id: row.id,
                    data: formater(row.data_execucao),
                    status: row.status,
                    rest: row.separacao_itens
                };
            });
            setTableRows(tableRow);
            setLoading(false);
            console.log('🚀 ~ getPrimeiroNivel ~ data:', data);
        } catch (error) {
            console.log('🚀 ~ getPrimeiroNivel ~ error:', error);
        }
    }

    useEffect(() => {
        getPrimeiroNivel();
        getSolicitacao(id);
    }, [id]);

    //itens do datagrid

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'data', headerName: 'Data', flex: 1 },
        { field: 'status', headerName: 'Status', flex: 1 },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <div>
                        <Tooltip title="Deletar">
                            <IconButton
                                onClick={() => {
                                    setOpenDeleteModal(true);
                                    setCurrentRowId(params.row.id);
                                }}
                            >
                                <DeleteOutlineIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="Análisar"
                            onClick={() => {
                                setOpenAnalysesModal(true);
                                setCurrentRowId(params.row.id);
                            }}
                        >
                            <IconButton>
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }
        }
    ];

    // codgo referente ao delete

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [currentRowId, setCurrentRowId] = useState('');

    async function handleDeleteSeparacao(rowId) {
        try {
            setDeleteLoading(true);
            await api.deleteSeparacao(rowId);
            getPrimeiroNivel();
            toast.success('Separação deletada com sucesso');
            setOpenDeleteModal(false);
            setDeleteLoading(false);
        } catch (error) {
            setDeleteLoading(false);
            toast.error(error.response.data.message);
            console.log('🚀 ~ handleDeleteSeparacao ~ error:', error);
        }
    }

    return (
        <MainCard title="Separações Realizadas" sx={{ position: 'relative' }}>
            <Grid container spacing={gridSpacing}>
                <DeleteRowModal
                    open={openDeleteModal}
                    setOpen={setOpenDeleteModal}
                    deleteLoading={deleteLoading}
                    deleteFunction={() => handleDeleteSeparacao(currentRowId)}
                />
                <AnalyseModal open={openAnalysesModal} setOpen={setOpenAnalysesModal} currentId={currentRowId} />
                <Button
                    disabled={solicitacao?.status_separacao == 'Encerrado'}
                    sx={{ position: 'absolute', top: '20px', right: '20px' }}
                    variant="contained"
                    onClick={() => navigate(`/main/separacao/${id}`)}
                >
                    Adicionar separação
                </Button>
                <Box sx={{ marginTop: '10px', width: '100%' }}>
                    {loading && <p style={{ textAlign: 'center' }}>Carregando...</p>}
                    {!loading && tableRows.length === 0 && <p style={{ textAlign: 'center' }}>Nenhuma separação realizada</p>}
                    {!loading && tableRows.length > 0 && <DataGrid columns={columns} rows={tableRows} hideFooter />}
                </Box>
            </Grid>
        </MainCard>
    );
};

export default PrimeiroNivel;
