import { TableCell, styled } from '@mui/material';
import { Box } from '@mui/material';

// components

export const MainBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    marginTop: '1.5em',
    padding: '.5em'
});

export const HeaderBox = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em',
    marginLeft: '.7em',
    marginTop: '1em'
});

export const TableBox = styled(Box)({
    width: '100%',
    padding: '1em',
    height: '450px'
});

export const OrderButton = styled(TableCell)({
    cursor: 'pointer',
    transition: 'all .15s ease',

    b: {
        marginRight: '1em'
    },
    '&:hover': {
        backgroundColor: 'rgba(39, 157, 245, 0.52)'
    }
});

export const OrderNameButton = styled(TableCell)({
    cursor: 'pointer',
    transition: 'all .15s ease',

    b: {
        marginRight: '1em'
    },
    '&:hover': {
        backgroundColor: 'rgba(39, 157, 245, 0.52)'
    }
});
