import React from 'react';
import { useApi } from 'Service/axios';
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControl,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LogoutIcon from '@mui/icons-material/Logout';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

const ActionButtonEdit = ({
    setCampos,
    itens,
    setItens,
    row,
    atualizarCampoItem,
    removerCampoItem,
    listAllItens,
    listAllAlmoxarifado,
    listAllLocalizacao
}) => {
    /////////////////////////////// INSTANCIAS /////////////////////////
    const api = useApi();
    /////////////////////////////// STATES /////////////////////////
    const [open, setOpen] = useState(false);
    const [itemSelecionado, setItemSelecionado] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    /////////////////////////////// FUNÇÕES /////////////////////////
    const handleOpen = async () => {
        setOpen(true);
    };

    const handleItemSelect = (selectedItemId) => {
        const selectedItem = listAllItens.find((item) => item.id === selectedItemId);
        setItemSelecionado({
            ...itemSelecionado,
            item_id: selectedItem.id,
            descricao: selectedItem.descricao,
            unidade: selectedItem.unidade_medida.descricao
        });
    };

    const atualizarCampoItemSelecionado = (item, campo, valor) => {
        setItemSelecionado({
            ...item,
            [campo]: campo === 'item_id' || campo === 'quantidade' ? parseInt(valor) : valor
        });
    };

    const handleEditItem = (itemSelecionado) => {
        const selectedItem = listAllItens.find((item) => item.id === itemSelecionado.item_id);
        itemSelecionado.item = selectedItem;
        itemSelecionado.indiceCampoItem = row.indiceCampoItem;

        setItens((prevItens) => {
            const novosItens = prevItens.map((item) => {
                // Verifique se o item.id é igual ao itemSelecionado.id
                if (item.id === itemSelecionado.id) {
                    // Se for igual, retorne o itemSelecionado para atualização
                    return itemSelecionado;
                }
                // Mantenha outros itens inalterados
                return item;
            });

            return novosItens;
        });
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            const indiceItem = novosCampos[0].itens.findIndex((item) => item.indiceCampoItem === itemSelecionado.indiceCampoItem);

            if (indiceItem !== -1) {
                const item = novosCampos[0].itens[indiceItem];
                item.action = 'update';
            }
            return novosCampos;
        });

        setOpen(false);
    };

    useEffect(() => {
        setItemSelecionado({ ...row });
    }, []);

    return (
        <Box>
            <Dialog open={openDialog}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>
                    <Typography>Ao fechar todo o cadastro desse item sera cancelado, continuar?</Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex' }}>
                        <Button color="error" onClick={() => setOpenDialog(false)}>
                            Cancelar
                        </Button>
                        <Button onClick={() => removerCampoItem(0, row.indiceCampoItem)}>Confirmar</Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Drawer anchor="right" open={open}>
                <Box sx={{ width: '450px', padding: '.75em', height: '100%' }}>
                    {itemSelecionado !== null && (
                        <Box>
                            <Box sx={{ marginTop: '1em', marginBottom: '2em', display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="h3">Editar item</Typography>
                                <Tooltip title="Fechar">
                                    <Button onClick={() => setOpen(false)}>
                                        <CloseIcon />
                                    </Button>
                                </Tooltip>
                            </Box>
                            <FormControl fullWidth>
                                <TextField
                                    label="Item"
                                    select
                                    value={itemSelecionado ? itemSelecionado.item_id : ''}
                                    onChange={(e) => handleItemSelect(e.target.value)}
                                >
                                    {listAllItens.map((i) => (
                                        <MenuItem key={i.id} value={i.id}>
                                            {i.descricao}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Quantidade"
                                        type="number"
                                        value={parseFloat(itemSelecionado.quantidade)}
                                        onChange={(e) =>
                                            atualizarCampoItemSelecionado(itemSelecionado, 'quantidade', parseFloat(e.target.value))
                                        }
                                    />
                                </FormControl>
                            </Box>

                            <Box sx={{ display: 'flex', marginTop: '2em', justifyContent: 'flex-end', gap: '1em' }}>
                                <Button variant="contained" onClick={(e) => handleEditItem(itemSelecionado)}>
                                    <SaveIcon />
                                </Button>
                                <Tooltip title="Fechar e cancelar">
                                    <Button variant="contained" color="error" onClick={() => setOpenDialog(true)}>
                                        <DeleteIcon />
                                    </Button>
                                </Tooltip>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Drawer>
            <Box>
                <Tooltip title="Editar Item">
                    <Button onClick={handleOpen}>
                        <EditIcon />
                    </Button>
                </Tooltip>
            </Box>
        </Box>
    );
};

export default ActionButtonEdit;
