import { TableCell, styled } from '@mui/material';
import { Box } from '@mui/material';

export const TableBox = styled(Box)({
    width: '100%',
    padding: '1em',
    height: '550px',
    display: 'flex',
    flexDirection: 'column'
});
