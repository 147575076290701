import React from 'react';
import { Grid } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { gridSpacing } from 'store/constant';
import TablePagination from '@mui/material/TablePagination';
import EditUserFormPrincipal from '../../views/users/EditUserForms/EditUserFormPrincipal';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { toast } from 'react-toastify';

function EditUser() {
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openSnakBar, setOpenSnackBar] = React.useState(false);
    const [openSnakBarDeleted, setOpenSnackBarDeleted] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [idSelect, setId] = useState();

    // GET USERS FRO DB
    const get = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            // redireciona o usuário para a tela de login
            navigate('/login');
            return;
        }

        try {
            const response = await api.getAllUsers();
            setData(response.data.allUsers);
        } catch (error) {
            // redireciona o usuário para a tela de login
            navigate('/login');
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClickOpenEdit = (e, id) => {
        e.preventDefault();
        setOpenEdit(true);
        setId(id);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        get();
    };
    const handleClickOpenDelete = (e, id) => {
        e.preventDefault();
        setOpenDelete(true);
        setId(id);
    };

    const handleCloseDelete = async () => {
        await setOpenDelete(false);
        if (openDelete) return setOpenSnackBar(true);
    };

    const handleConfirmDelete = async () => {
        await setOpenDelete(false);

        if (openDelete) return setOpenSnackBarDeleted(true);
    };
    const handleClickSnackBar = () => {
        setOpenSnackBar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };

    const handleCloseSnackBarDeleted = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBarDeleted(false);
    };
    const handleCancelDeleteDialog = async () => {
        await handleCloseDelete();
    };

    const handleConfirmDeleteDialog = async (idSelect) => {
        try {
            const response = await api.deleteUser(idSelect);
            toast.success('Usuário deletado!');
            setOpenDelete(false);
            get();
            return response;
        } catch (error) {
            if (error.response.data.mensagem) toast.error(error.response.data.mensagem);
            setOpenDelete(false);
        }
    };

    // USERS LIST CONTROL
    const [data, setData] = useState([]);
    const api = useApi();

    useEffect(() => {
        get();
    }, []);

    return (
        <>
            {/* abaixo dialog para editar */}
            <Dialog open={openEdit} onClose={handleCloseEdit}>
                <DialogTitle>Editar registro</DialogTitle>
                <DialogContent>
                    <DialogContentText>Atualize os registros do colaborador</DialogContentText>
                    <EditUserFormPrincipal id={idSelect} setOpenEdit={setOpenEdit} />
                </DialogContent>
                {/*<DialogActions>
                    <Button onClick={handleCloseEdit}>Concluir</Button>
                    <Button onClick={handleCloseEdit}>Cancelar</Button>
                </DialogActions>*/}
            </Dialog>
            {/* abaixo dialog para deletar */}
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title-delete"
                aria-describedby="alert-dialog-description-delete"
            >
                <DialogTitle id="alert-dialog-title-delete">{'Deletar Registro'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description-delete">Tem certeza que deseja excluir o registo ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirmDeleteDialog(idSelect)}>Sim</Button>
                    <Button onClick={handleCancelDeleteDialog}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            {/* abaixo logica pra quando cancelar o delete */}
            <Snackbar open={openSnakBar} autoHideDuration={1000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                    Cancelado com sucesso!
                </Alert>
            </Snackbar>
            {/* abaixo logica pra quando confirmar o delete */}

            <Snackbar open={openSnakBarDeleted} autoHideDuration={1000} onClose={handleCloseSnackBarDeleted}>
                <Alert onClose={handleCloseSnackBarDeleted} severity="success" sx={{ width: '100%' }}>
                    Deletado com sucesso!
                </Alert>
            </Snackbar>

            {/* abaixo tabela */}
            <MainCard>
                <Grid container spacing={gridSpacing} justifyContent="center">
                    <Grid item xs={12} sm={12}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell>Usuário</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Celular</TableCell>
                                        <TableCell>Setor</TableCell>
                                        <TableCell>Senha</TableCell>
                                        <TableCell align="right">Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell>{row.nome_completo}</TableCell>
                                            <TableCell>{row.username}</TableCell>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.telefone}</TableCell>
                                            <TableCell>{row.setor.toUpperCase()}</TableCell>
                                            <TableCell>************</TableCell>
                                            <TableCell align="right">
                                                <Tooltip
                                                    title="Editar"
                                                    onClick={(e) => {
                                                        handleClickOpenEdit(e, row.id);
                                                    }}
                                                >
                                                    <IconButton>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Excluir" onClick={(e) => handleClickOpenDelete(e, row.id)}>
                                                    <IconButton>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            </MainCard>
        </>
    );
}

export default EditUser;
