import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Box } from '@mui/system';

export function DeleteRowModal({ open, setOpen, deleteLoading, deleteFunction }) {
    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Atenção!</DialogTitle>
            <DialogContent>
                <Typography>Deseja realmente excluir esta separação?</Typography>
            </DialogContent>
            <DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1em' }}>
                    <Button variant="contained" color="error" onClick={() => setOpen(false)}>
                        Não
                    </Button>
                    <Button variant="contained" onClick={deleteFunction} disabled={deleteLoading}>
                        {deleteLoading ? 'Aguarde...' : 'Confirmar'}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
