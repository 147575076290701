import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Autocomplete, TextField } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { useApi } from 'Service/axios';
import { useNavigate, Link } from 'react-router-dom';
import CadastroAlmoxarifado from 'components/Almoxarifado/CadastroAlmoxarifado';

// components
import { MainBox, FormBox, TableBox, HeaderBox } from './style';
import { toast } from 'react-toastify';

function Almoxarifado() {
    const api = useApi();
    const navigate = useNavigate();
    const [formOpen, setFormOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [almoxarifadoId, setAlmoxarifadoId] = useState(null);
    const [almoxarifados, setAlmoxarifados] = useState([]);
    const [selectedAlmoxarifado, setSelectedAlmoxarifado] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getAllAlmoxarifados = async () => {
        const response = await api.getAllAlmoxarifado();
        setAlmoxarifados(response.data.almoxarifados);
    };

    // delete functions
    const handleClickOpenDelete = (e, id) => {
        e.preventDefault();
        setOpenDelete(true);
        setAlmoxarifadoId(id);
    };
    const handleCloseDelete = (e) => {
        e.preventDefault();
        setOpenDelete(false);
    };
    const handleCancelDeleteDialog = () => {
        handleCloseDelete();
    };
    const handleConfirmDeleteDialog = async (almoxarifadoId) => {
        try {
            const deletedCategory = await api.deleteAlmoxarifado(almoxarifadoId);
            toast.success('Deletado com sucesso!');
            getAllAlmoxarifados();
            setOpenDelete(false);
        } catch (error) {
            if (error.response.status === 500) {
                toast.error('Erro. Este almoxarifado já esta vinculado a um item ou possui localização vinculada');
            }
            toast.error(error.message);
            setOpenDelete(false);
        }
    };

    useEffect(() => {
        getAllAlmoxarifados();
    }, []);

    return (
        <MainCard title="Almoxarifado">
            <Grid container spacing={gridSpacing}>
                <MainBox>
                    <Dialog
                        open={openDelete}
                        onClose={handleCloseDelete}
                        aria-labelledby="alert-dialog-title-delete"
                        aria-describedby="alert-dialog-description-delete"
                    >
                        <DialogTitle id="alert-dialog-title-delete">{'Deletar Registro'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description-delete">
                                Tem certeza que deseja excluir o registo ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleConfirmDeleteDialog(almoxarifadoId)}>Sim</Button>
                            <Button onClick={handleCancelDeleteDialog}>Cancelar</Button>
                        </DialogActions>
                    </Dialog>
                    {formOpen && (
                        <FormBox>
                            <Button variant="contained" onClick={() => setFormOpen(!formOpen)}>
                                Ver Tabela
                            </Button>
                            <Box sx={{ width: '80%', margin: '2em' }}>
                                <CadastroAlmoxarifado getAllAlmoxarifados={getAllAlmoxarifados} />
                            </Box>
                        </FormBox>
                    )}

                    {!formOpen && (
                        <TableBox sx={{ width: '100%' }}>
                            <HeaderBox>
                                <Button variant="contained" onClick={() => setFormOpen(!formOpen)}>
                                    Cadastrar almoxarifado
                                </Button>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={almoxarifados}
                                    getOptionLabel={(almoxarifado) => almoxarifado.descricao}
                                    value={selectedAlmoxarifado}
                                    onChange={(event, newValue) => {
                                        setSelectedAlmoxarifado(newValue);
                                    }}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Pesquisar almoxarifado" />}
                                />
                            </HeaderBox>

                            <TableContainer
                                component={Paper}
                                style={{
                                    width: '100%',
                                    maxHeight: '600px',
                                    overflowY: 'auto',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor: 'rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.3)'
                                }}
                            >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell align="right">DESCRIÇÃO ALMOXARIFADO</TableCell>
                                            <TableCell align="right">AÇÕES</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {almoxarifados.length > 0 &&
                                            almoxarifados

                                                .filter((almoxarifado) =>
                                                    selectedAlmoxarifado
                                                        ? almoxarifado.descricao.includes(selectedAlmoxarifado.descricao)
                                                        : true
                                                )
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((item) => (
                                                    <TableRow key={item.id}>
                                                        <TableCell>{item.id}</TableCell>
                                                        <TableCell align="right">{item.descricao}</TableCell>
                                                        <TableCell align="right">
                                                            <Tooltip title="Editar">
                                                                <Link to={`/main/edit/almoxarifado/${item.id}`}>
                                                                    <IconButton>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                </Link>
                                                            </Tooltip>
                                                            <Tooltip title="Excluir">
                                                                <IconButton onClick={(e) => handleClickOpenDelete(e, item.id)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={almoxarifados.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableBox>
                    )}
                </MainBox>
            </Grid>
        </MainCard>
    );
}

export default Almoxarifado;
