import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, TextField, Typography, Box, InputLabel, Select, MenuItem, ButtonGroup, FormLabel } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';

// components
import EditarLocalizacaoForm from '../../../components/Localizacao/EditarLocalizacao/index';

const EditarLocalizacao = () => {
    const { id } = useParams();
    return (
        <MainCard title="Editar localização">
            <Grid container spacing={gridSpacing}>
                <Box sx={{ width: '80%', margin: '0 auto', marginTop: '3em' }}>
                    <EditarLocalizacaoForm />
                </Box>
            </Grid>
        </MainCard>
    );
};

export default EditarLocalizacao;
