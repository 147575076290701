import { useParams, Link, useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    Box,
    TablePagination,
    Autocomplete,
    Tooltip,
    TextField,
    FormControl,
    MenuItem,
    Typography,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    InputLabel,
    Input,
    CircularProgress
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import ActionButtons from 'components/Entrada/ActionButtons';
import ActionButtonsCreate from 'components/Entrada/ActionButtonsCreate';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
// ============================= INITIALSTATE =============================
const initialState = {
    documento: '',
    emissao: '',
    movimento: new Date(),
    fornecedor: 0,
    observacao: '',
    itens: []
};

const NovaEntrada = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    const navigate = useNavigate();
    // ============================= STATES =============================
    const [campos, setCampos] = useState([initialState]);
    const [openCreate, setOpenCreate] = useState(false);
    const [itens, setItens] = useState([]);
    const [listAllItens, setListAllItens] = useState([]);
    const [listAllAlmoxarifado, setListAllAlmoxarifado] = useState([]);
    const [listAllLocalizacao, setListAllLocalizacao] = useState([]);
    const [listAllFornecedor, setListAllFornecedor] = useState([]);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 1 },
        { field: 'unidade', headerName: 'UN', flex: 1 },
        { field: 'quantidade', headerName: 'QUANTIDADE', flex: 1 },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <ActionButtons
                    itens={itens}
                    setItens={setItens}
                    row={params.row}
                    atualizarCampoItem={atualizarCampoItem}
                    removerCampoItem={removerCampoItem}
                    listAllItens={listAllItens}
                    listAllAlmoxarifado={listAllAlmoxarifado}
                    listAllLocalizacao={listAllLocalizacao}
                />
            )
        }
    ];

    // ============================= FUNÇÕES =============================
    const somaValorTotal = itens.reduce((acumulador, objeto) => {
        return acumulador + Number(objeto.valor_total);
    }, 0);

    const atualizarCampo = (indiceCampo, campo, valor) => {
        setCampos((prevCampos) => {
            if (campo === 'fornecedor') {
                const novosCampos = [...prevCampos];
                novosCampos[indiceCampo][campo] = parseInt(valor);
                return novosCampos;
            } else {
                const novosCampos = [...prevCampos];
                novosCampos[indiceCampo][campo] = valor;
                return novosCampos;
            }
        });
    };

    useEffect(() => {
        console.log(itens, ' itens ');
    }, [itens]);

    const adicionarCampoItem = (indiceCampo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].itens.push({
                item_id: '',
                descricao: '',
                quantidade: 0,
                unidade: '',
                indiceCampoItem: '',
                valor_unitario: 0,
                valor_total: 0,
                almoxarifado_id: '',
                localizacao_id: '',
                lote: '',
                validade: ''
            });
            return novosCampos;
        });
    };

    const removerCampoItem = (indiceCampo, indiceCampoItem) => {
        setItens((prevItens) => {
            const novosItens = [...prevItens];
            const teste = novosItens.find((item) => item.id === indiceCampoItem + 1);
            const filtredItens = novosItens.filter((item) => item !== teste);
            return filtredItens;
        });
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[0].itens.splice(indiceCampoItem, 1);
            return novosCampos;
        });
        if (openCreate) {
            setOpenCreate(false);
        }
    };

    const atualizarCampoItem = (indiceCampo, indiceCampoItem, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].itens[indiceCampoItem][campo] = valor;
            return novosCampos;
        });
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const handleClose = () => {
        setOpenCreate(false);
    };

    const handleOpenAndCreateCampoItem = (indiceCampo) => {
        setOpenCreate(true);
        adicionarCampoItem(indiceCampo);
    };

    const handleCreate = (indiceCampo) => {
        adicionarCampoItem(indiceCampo);
    };

    const getListAllItens = async () => {
        const response = await api.getAllItens();
        setListAllItens(response.data.itens);
    };

    const getListAllAlmoxarifado = async () => {
        const response = await api.getAllAlmoxarifado();
        setListAllAlmoxarifado(response.data.almoxarifados);
    };

    const getListAllLocalizacao = async () => {
        const response = await api.getAllLocalizacao();
        setListAllLocalizacao(response.data.localizacao);
    };

    const getAllFonecedor = async () => {
        const response = await api.getAllFornecedor();
        setListAllFornecedor(response.data.fornecedores);
    };

    const getDate = () => {
        const date = new Date();
        let currentDay = String(date.getDate()).padStart(2, '0');
        let currentMonth = String(date.getMonth() + 1).padStart(2, '0');
        let currentYear = date.getFullYear();
        let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[0].movimento = currentDate;
            return novosCampos;
        });
    };

    useEffect(() => {
        getListAllItens();
        getListAllAlmoxarifado();
        getListAllLocalizacao();
        getAllFonecedor();
        getDate();
    }, []);

    //====== SUBMIT ======
    const [load, setLoad] = useState(false);
    const handleSubmit = async (e, campo) => {
        setLoad(true);
        e.preventDefault();
        itens.forEach((item) => {
            if (item.validade === '') {
                item.validade = null;
            }
            if (item.lote === '') {
                item.lote = null;
            }
            delete item.id;
        });
        const data = { entrada: campo, itens: itens };

        try {
            await api.createEntradaEstoque(data.entrada, data.itens);
            setCampos(undefined);
            setCampos([
                {
                    documento: '',
                    emissao: '',
                    movimento: new Date(),
                    fornecedor: 0,
                    observacao: '',
                    itens: []
                }
            ]);
            setItens([]);
            setOpenCreate(false);
            toast.success('Entrada cadastrada');
            setLoad(false);
            navigate(-1);
        } catch (error) {
            toast.error(`Erro ao criar entrada: ${error.message}`);
        }
    };

    return (
        <MainCard title="Nova Entrada">
            <Grid container spacing={gridSpacing}>
                <Box sx={{ width: '80%', margin: '0 auto' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginLeft: '1em', marginTop: '1em' }}>
                        {campos.map((campo, indiceCampo) => (
                            <>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '1em',
                                            width: '100%',
                                            marginBottom: '1em'
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <TextField
                                                type="text"
                                                placeholder="Documento"
                                                label="Documento"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={campo.documento}
                                                onChange={(e) => atualizarCampo(indiceCampo, 'documento', e.target.value)}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                type="date"
                                                value={campo.emissao}
                                                onChange={(e) => atualizarCampo(indiceCampo, 'emissao', e.target.value)}
                                                label="Emissão"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                type="date"
                                                value={campo.movimento}
                                                disabled
                                                onChange={(e) => atualizarCampo(indiceCampo, 'movimento', e.target.value)}
                                                label="Movimento"
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                select
                                                value={campo.fornecedor}
                                                label="Fornecedor"
                                                onChange={(e) => atualizarCampo(indiceCampo, 'fornecedor', e.target.value)}
                                            >
                                                {listAllFornecedor.map((fornecedor) => (
                                                    <MenuItem key={fornecedor.id} value={fornecedor.id}>
                                                        {fornecedor.nome_fantasia}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormControl sx={{ width: '50%' }}>
                                            <TextField
                                                multiline
                                                minRows={3}
                                                placeholder="Observação"
                                                value={campo.observacao}
                                                onChange={(e) => atualizarCampo(indiceCampo, 'observacao', e.target.value)}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box sx={{ width: '100%', marginTop: '2em', borderTop: '2px dashed #eee' }}>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '1em' }}>
                                        <Box>
                                            <Typography variant="h4">Relação de itens</Typography>
                                        </Box>
                                        <Box>
                                            <Tooltip title="Adicionar item">
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    onClick={(e) => handleOpenAndCreateCampoItem(indiceCampo)}
                                                >
                                                    <AddIcon />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>

                                    <Box sx={{ marginTop: '2em', display: 'flex', flexDirection: 'column', height: '300px' }}>
                                        <DataGrid
                                            rows={itens}
                                            columns={columns}
                                            hideFooter={true}
                                            onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                                        />
                                        <Box
                                            sx={{
                                                width: '40%',
                                                marginLeft: 'auto',
                                                marginTop: '1em',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <h4>Valor Total:</h4>
                                                <Box
                                                    sx={{
                                                        border: '1px solid #ccc',
                                                        padding: '8px 16px',
                                                        borderRadius: 2
                                                    }}
                                                >
                                                    {somaValorTotal.toFixed(2)}
                                                </Box>
                                            </Box>

                                            <Tooltip title="Salvar entrada">
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    disabled={load}
                                                    onClick={(e) => handleSubmit(e, campo)}
                                                >
                                                    {load ? <CircularProgress size={24} /> : <SaveIcon />}
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>
                                {campos[0]?.itens.map((item, indiceCampoItem) => (
                                    <ActionButtonsCreate
                                        key={indiceCampoItem}
                                        open={openCreate}
                                        handleClose={handleClose}
                                        itens={itens}
                                        setItens={setItens}
                                        campos={campos}
                                        setCampos={setCampos}
                                        adicionarCampoItem={adicionarCampoItem}
                                        atualizarCampoItem={atualizarCampoItem}
                                        removerCampoItem={removerCampoItem}
                                        indiceCampoItem={indiceCampoItem}
                                        item={item}
                                        listAllItens={listAllItens}
                                        listAllAlmoxarifado={listAllAlmoxarifado}
                                        listAllLocalizacao={listAllLocalizacao}
                                        handleCreate={handleCreate}
                                    />
                                ))}
                            </>
                        ))}
                    </Box>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default NovaEntrada;
