import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, TextField, Typography, Box, InputLabel, Select, MenuItem, ButtonGroup, FormLabel } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';

// components
import EditarcentroCustoForm from 'components/CentroCusto/EditarCentroCusto/index';

const EditarCentroCusto = () => {
    const { id } = useParams();
    return (
        <MainCard title="Editar Centro de Custo">
            <Grid container spacing={gridSpacing}>
                <Box sx={{ width: '80%', margin: '0 auto', marginTop: '3em' }}>
                    <EditarcentroCustoForm />
                </Box>
            </Grid>
        </MainCard>
    );
};

export default EditarCentroCusto;
