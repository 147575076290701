import React, { useState } from 'react';
// Context
import { createContext } from 'react';
// hooks
import useAuth from 'hooks/useAuth';

const Context = createContext();

function UserProvider({ children }) {
    const { authenticated, errorMessage, loggedUser, login, registerUser, logout } = useAuth();
    const [sector, setSector] = useState('');
    const [itensSeparacao, setItensSeparacao] = useState([{}]);

    return (
        <Context.Provider
            value={{
                authenticated,
                errorMessage,
                loggedUser,
                login,
                registerUser,
                logout,
                sector,
                setSector,
                itensSeparacao,
                setItensSeparacao
            }}
        >
            {children}
        </Context.Provider>
    );
}

export { Context, UserProvider };
