import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Typography, TextField, MenuItem, Autocomplete } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { TableBox } from './style';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';

const SaldoGeral = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    // ============================= STATES =============================
    const [itens, setItens] = useState([]);
    const [data, setData] = useState([]);
    const [unmutableData, setUnmatableData] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const [saldoTotal, setSaldoTotal] = useState(0);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'item_id', headerName: 'Item ID', flex: 2, valueGetter: (params) => params.row.item.id },
        { field: 'item', headerName: 'Descrição', flex: 2, valueGetter: (params) => params.row.item.descricao },
        { field: 'unidade', headerName: 'UN', flex: 1, valueGetter: (params) => params.row.item.unidade_medida.descricao },
        { field: 'almoxarifado', headerName: 'Almoxarifado', flex: 2, valueGetter: (params) => params.row.almoxarifado.descricao },
        { field: 'localizacao', headerName: 'Localização', flex: 1, valueGetter: (params) => params.row.localizacao.descricao },
        { field: 'saldo', headerName: 'Saldo', flex: 2, valueGetter: (params) => params.row.quantidade_disponivel }
    ];
    // ============================= FUNÇÕES =============================

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const handleFilterItem = (item) => {
        const itemData = unmutableData.filter((el) => el.item_id === item).map((item, index) => ({ ...item, id: index + 1 }));
        let saldoTotal = 0;
        itemData.forEach((item) => {
            saldoTotal += item.quantidade_disponivel;
        });
        setSaldoTotal(saldoTotal);
        setData(itemData);
    };

    const handleResetFilter = () => {
        setData([]);
        setSelectedItem('');
        setSaldoTotal(0);
    };

    const getItens = async () => {
        try {
            const response = await api.getAllItens();
            setItens(response.data.itens);
        } catch (error) {
            toast.error(`Erro ao buscar itens: ${error.response.data.message}`);
        }
    };

    const getMovSum = async () => {
        try {
            const response = await api.getMovSumarizados();
            const formatedData = await response.data.map((item, index) => ({
                ...item,
                id: index + 1
            }));
            setUnmatableData(formatedData);
        } catch (error) {
            toast.error(`Erro ao buscar movimentos: ${error.message}`);
        }
    };

    useEffect(() => {
        getItens();
        getMovSum();
    }, []);

    return (
        <MainCard title="Saldo Geral">
            <Grid container spacing={gridSpacing}>
                <TableBox>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: '1em', marginBottom: '1em' }}>
                        <Autocomplete
                            sx={{ width: '60%' }}
                            value={itens.find((el) => el.id === selectedItem) || null}
                            onChange={(event, newValue) => {
                                setSelectedItem(newValue ? newValue.id : '');
                            }}
                            options={itens}
                            getOptionLabel={(option) => `${option.id} - ${option.descricao}`}
                            renderInput={(params) => <TextField {...params} label="Item" />}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
                            <Button variant="contained" color="error" onClick={handleResetFilter} sx={{ height: '40px' }}>
                                Limpar
                            </Button>
                            <Button variant="contained" onClick={() => handleFilterItem(selectedItem)} sx={{ height: '40px' }}>
                                Pesquisar
                            </Button>
                        </Box>
                    </Box>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        hideFooter={false}
                        onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                    />
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '1em' }}>
                        <TextField disabled label="Saldo Total" value={saldoTotal} InputLabelProps={{ shrink: true }} />
                    </Box>
                </TableBox>
            </Grid>
        </MainCard>
    );
};

export default SaldoGeral;
