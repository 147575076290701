import { Button, Dialog, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import TabelaSeparacaoMateriais from '../TabelaSeparacao';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import SaveIcon from '@mui/icons-material/Save';
import { useContext } from 'react';
import { Context } from 'context/UserContext';

const ModalTabelaSeparacao = ({ openModal, onClose, itemId, solicitacao, separacaoPayload, setSeparacaoPayload, rest }) => {
    // ==== INSTANCIAS ====
    const api = useApi();
    // ==== STATES ====
    const [saldoItem, setSaldoItem] = useState([]);
    const [itens, setItens] = useState([]);
    const { itensSeparacao, setItensSeparacao } = useContext(Context);
    // ==== FUNÇÕES ====
    const handleChange = (novosItens) => {
        // Exemplo: Atualizar o estado com os novos itens

        setSeparacaoPayload((prevPayload) => ({
            ...prevPayload,

            itens: novosItens
        }));
    };

    const salvarItens = () => {
        // Substitua o item editado no estado 'itens'

        setItens((prevPayload) => {
            const itemEditadoIndex = prevPayload.findIndex(
                (el) =>
                    el.item.id === saldoItem[0]?.item_id &&
                    el.almoxarifado_id === saldoItem[0]?.almoxarifado_id &&
                    el.localizacao_id === saldoItem[0]?.localizacao_id
            );

            if (itemEditadoIndex !== -1) {
                const novosItens = [...prevPayload];
                novosItens[itemEditadoIndex] = separacaoPayload.itens.find(
                    (el) =>
                        el.item.id === saldoItem[0]?.item_id &&
                        el.almoxarifado_id === saldoItem[0]?.almoxarifado_id &&
                        el.localizacao_id === saldoItem[0]?.localizacao_id
                );
                return novosItens;
            }

            // Se não encontrar, adicione o novo item editado
            return [
                ...prevPayload,
                separacaoPayload.itens.find(
                    (el) =>
                        el.item.id === saldoItem[0]?.item_id &&
                        el.almoxarifado_id === saldoItem[0]?.almoxarifado_id &&
                        el.localizacao_id === saldoItem[0]?.localizacao_id
                )
            ];
        });

        setItensSeparacao(itens);

        onClose();
    };

    const formatarResponseData = (responseData, solicitacaoItems) => {
        return responseData.map((item) => {
            const solicitacaoItem = solicitacaoItems.find((el) => el.item.id === item.item_id);

            if (solicitacaoItem) {
                return {
                    ...item,
                    solicitado: solicitacaoItem.quantidade,
                    solicitacao_item_id: solicitacaoItem.id
                };
            }

            return item;
        });
    };
    const getSaldoInfo = async (itemId) => {
        try {
            const response = await api.getMovSumarizadoByItemId(itemId);

            const formatarDados = formatarResponseData(response.data, solicitacao.item_id);

            // Verifica se os itens já existem no array antes de adicionar
            const itensExistentes = formatarDados.filter(
                (novoItem) => !itens.some((itemExistente) => itemExistente.item_id === novoItem.item_id)
            );

            // Adiciona os novos itens ao array
            setSeparacaoPayload((prevState) => ({
                solicitacao_id: solicitacao.id,
                itens: [...prevState.itens, ...itensExistentes]
            }));

            setSaldoItem(formatarDados);
        } catch (error) {
            toast.error('Erro ao buscar saldo do item');
        }
    };

    useEffect(() => {
        if (openModal) {
            getSaldoInfo(itemId);
        }
        setItensSeparacao(itens);
    }, [itemId, itens]);

    return (
        <Dialog open={openModal} onClose={onClose} fullWidth={true} maxWidth={'lg'}>
            <Box sx={{ marginTop: '3em', padding: '3rem' }}>
                <Typography variant="h3" sx={{ paddingBottom: '0.5em', textAlign: 'center' }}>
                    Separação
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center', marginBottom: '5px' }}>
                    <Typography variant="subtitle1">Total solicitado: {saldoItem[0]?.solicitado}</Typography>
                    <Typography variant="subtitle1">Total restante: {rest}</Typography>
                </Box>
                <Divider />
                <TabelaSeparacaoMateriais
                    saldoItem={saldoItem}
                    handleChange={handleChange}
                    separacaoPayload={separacaoPayload}
                    itens={itens}
                    rest={rest}
                />
                <Button sx={{ marginLeft: '87.5%', marginRight: '-5%', marginTop: '2em' }} variant="contained" onClick={salvarItens}>
                    <SaveIcon sx={{ marginRight: '5px', paddingRight: '3px' }} />
                </Button>
            </Box>
        </Dialog>
    );
};

export default ModalTabelaSeparacao;
