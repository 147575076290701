import {
    Button,
    CircularProgress,
    Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { Box } from '@mui/system';
import { useApi } from 'Service/axios';
import ModalTabelaSeparacao from 'components/Estoque/Separacao/ModalTabela';
import TabelaSeparacaoMateriais from 'components/Estoque/Separacao/TabelaSeparacao';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import formater from 'utils/DateDDMMYYY';
import { Context } from 'context/UserContext';
import { useContext } from 'react';
import CardDark from './component/CardDatk';
import LineStyleIcon from '@mui/icons-material/LineStyle';

const SeparacaoDeMaterial = () => {
    // CONTEXTO
    const { itensSeparacao } = useContext(Context);
    // ===== INSTÂNCIAS =====
    const { id } = useParams();
    const api = useApi();
    // ===== STATES =====
    const [solicitacao, setSolicitacao] = useState(null);
    const [itemId, setItemId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [separacaoPayload, setSeparacaoPayload] = useState({ solicitacao_id: parseInt(id), itens: [] });
    const [currentRest, setCurrentRest] = useState(null);

    // ===== FUNÇÕES =====

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleOpenModal = async (e, id) => {
        // Caso o item ainda não tenha registro de movSumarizado
        // mostra mensagem de erro
        const response = await api.getMovSumarizadoByItemId(id);
        if (!response.data?.[0]?.item_id) {
            return toast.error('Item ainda não possui resgistro de saldo no estoque');
        }

        setItemId(id);
        setOpenModal(true);
    };

    const getSolicitacao = async (id) => {
        try {
            const response = await api.getSolicitacaoById(id);
            setSolicitacao(response.data.data);
            setLoading(false);
        } catch (error) {
            toast.error('Erro ao buscar dados da solicitação');
        }
    };

    const [saveLoading, setSaveLoading] = useState(false);
    const createSeparacaoItens = async (separacaoData) => {
        setSaveLoading(true);
        const payload = { solicitacao_id: solicitacao.id, itens: separacaoData.itens };

        try {
            const response = await api.createSeparacao(payload);
            if (response.status === 201) {
                toast.success('Solicitação criada com sucesso!');
            }
            setSaveLoading(false);
            getSolicitacao(id);
        } catch (error) {
            setSaveLoading(false);
            toast.error(error.response.data.message);
        }
    };

    useEffect(() => {
        getSolicitacao(id);
    }, [id]);

    return (
        <MainCard title="Separação de Material">
            <Grid container spacing={gridSpacing}>
                {/* MODAL */}
                <ModalTabelaSeparacao
                    openModal={openModal}
                    onClose={handleClose}
                    itemId={itemId}
                    solicitacao={solicitacao}
                    separacaoPayload={separacaoPayload}
                    setSeparacaoPayload={setSeparacaoPayload}
                    rest={currentRest}
                />

                {loading && (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '1em' }}>
                        <CircularProgress />
                    </Box>
                )}

                {!loading && (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '1em',
                            marginTop: '1em'
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginBottom: '20px' }}>
                            <CardDark
                                icon={<LineStyleIcon />}
                                id={id}
                                necessidade={formater(solicitacao?.necessidade)}
                                obra={solicitacao?.obra?.descricao}
                                projeto={solicitacao?.projeto?.descricao}
                            />
                        </Box>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Item</TableCell>
                                        <TableCell align="center">UN</TableCell>
                                        <TableCell align="center">QTD. Restante</TableCell>
                                        <TableCell align="right">AÇÔES</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {solicitacao.item_id.map((item) => (
                                        <TableRow>
                                            <TableCell align="center">{item?.item?.descricao}</TableCell>
                                            <TableCell align="center">{item?.item?.unidade_medida?.descricao}</TableCell>
                                            <TableCell align="center">{item?.quantidade_restante}</TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    variant="contained"
                                                    onClick={(e) => {
                                                        handleOpenModal(e, item?.item?.id);
                                                        setCurrentRest(item?.quantidade_restante);
                                                    }}
                                                >
                                                    <AddToPhotosIcon sx={{ marginRight: '3px' }} /> Separar
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Box sx={{ width: '100%', display: 'flex', alignItems: 'end', justifyContent: 'flex-end', padding: '1.1%' }}>
                                <Button
                                    sx={{
                                        backgroundColor: '#00e676',
                                        marginTop: '15px',
                                        color: 'rgba(0, 0, 0, 0.87)'
                                        /*  marginLeft: '90.5%', marginRight: '1%' */
                                    }}
                                    onClick={() => createSeparacaoItens(separacaoPayload)}
                                >
                                    {saveLoading ? <CircularProgress size={20} /> : <SaveIcon />}
                                </Button>
                            </Box>
                        </TableContainer>
                    </Box>
                )}
            </Grid>
        </MainCard>
    );
};

export default SeparacaoDeMaterial;
