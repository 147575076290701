import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { MainBox, FormBox, TableBox } from './style';
import CadastroItens from '../../components/Item/CadastroItens/index';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtonsTabelaItens from 'components/Item/ActionButton';

function Itens() {
    // ===================== INSTANCIAS =====================
    const api = useApi();
    // ===================== STATES =====================
    const [formOpen, setFormOpen] = useState(false);
    const [itens, setItens] = useState([]);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 2 },
        { field: 'categoria', headerName: 'CATEGORIA', flex: 1, valueGetter: (params) => params.row.categoria_item.descricao },
        { field: 'apelido', headerName: 'APELIDO', flex: 1 },
        { field: 'unidade', headerName: 'UNIDADE', flex: 1, valueGetter: (params) => params.row.unidade_medida.descricao },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => <ActionButtonsTabelaItens row={params.row} getAllItems={getAllItems} />
        }
    ];
    // ===================== FUNÇÕES =====================

    const getAllItems = async () => {
        try {
            const response = await api.getAllItens();
            setItens(response.data.itens);
        } catch (error) {
            console.log('Erro ao buscar itens');
        }
    };

    useEffect(() => {
        getAllItems();
    }, []);

    return (
        <MainCard>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '2em'
                }}
            >
                <Typography sx={{ fontSize: '1.125rem', fontWeight: '500' }}>Item</Typography>
                <Button onClick={() => setFormOpen(!formOpen)} variant="contained">
                    Cadastrar Item
                </Button>
            </Box>
            <Grid container spacing={gridSpacing}>
                <MainBox>
                    {formOpen && (
                        <FormBox>
                            <Button variant="contained" onClick={() => setFormOpen(!formOpen)}>
                                Ver Tabela
                            </Button>
                            <Box sx={{ width: '80%' }}>
                                <CadastroItens setFormOpen={setFormOpen} getAllItems={getAllItems} />
                            </Box>
                        </FormBox>
                    )}

                    {!formOpen && (
                        <TableBox sx={{ width: '100%' }}>
                            <Box sx={{ width: '100%', height: '450px' }}>
                                <DataGrid
                                    rows={itens}
                                    columns={columns}
                                    hideFooter={false}
                                    onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                                />
                            </Box>
                        </TableBox>
                    )}
                </MainBox>
            </Grid>
        </MainCard>
    );
}

export default Itens;
