import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { TableBox } from './style';
import { toast } from 'react-toastify';
import DrawerCreateFornecedor from 'components/Fornecedor/DrawerCreate';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtonsFornecedor from 'components/Fornecedor/ActionButtons';

const Fornecedor = () => {
    // ============================= INSTÂNCIAS =========================
    const api = useApi();
    // ============================= STATES =============================
    const [allFornecedor, setAllFornecedor] = useState([]);
    const [open, setOpen] = useState(false);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'nome_fantasia', headerName: 'NOME', flex: 2 },
        { field: 'cnpj', headerName: 'CNPJ', flex: 1 },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => <ActionButtonsFornecedor row={params.row} getFornecedores={getFornecedores} />
        }
    ];
    // ============================= FUNÇÕES =============================

    const handleClose = () => {
        setOpen(false);
    };

    const getFornecedores = async () => {
        try {
            const response = await api.getAllFornecedor();
            setAllFornecedor(response.data.fornecedores);
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        getFornecedores();
    }, []);

    return (
        <MainCard>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '2em'
                }}
            >
                <Typography sx={{ fontSize: '1.125rem', fontWeight: '500' }}>Fornecedores</Typography>
                <Button variant="contained" size="medium" onClick={() => setOpen(true)} sx={{ height: '40px' }}>
                    Adicionar
                </Button>
            </Box>
            <Grid container spacing={gridSpacing}>
                <DrawerCreateFornecedor open={open} handleClose={handleClose} getFornecedores={getFornecedores} />
                <TableBox>
                    <DataGrid
                        rows={allFornecedor}
                        columns={columns}
                        hideFooter={true}
                        onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                    />
                </TableBox>
            </Grid>
        </MainCard>
    );
};

export default Fornecedor;
