import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const CancelDialog = ({ open, onClose, handelConfirmDialog, isDelete }) => {
    return (
        <Dialog open={open}>
            <DialogTitle>Atenção!</DialogTitle>
            <DialogContent>
                {isDelete ? (
                    <Typography>Deseja realmente excluir a transferência?</Typography>
                ) : (
                    <Typography>Deseja realmente cancelar o cadastro de nova transferência? Todo o progresso será perdido.</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1em' }}>
                    <Button variant="contained" color="error" onClick={onClose}>
                        Não
                    </Button>
                    <Button variant="contained" onClick={handelConfirmDialog}>
                        Confirmar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default CancelDialog;
