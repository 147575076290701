import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const CustomFlexBox = styled(Box)(({ width, flex, justifyContent, alignItems, gap }) => ({
    width: width || '100%',
    display: flex ? 'flex' : 'block',
    justifyContent: justifyContent || 'flex-start',
    alignItems: alignItems ? alignItems : 'flex-start',
    margin: '1em 0 1em 0',
    gap: gap || 0
}));
