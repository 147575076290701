'use strict';
import { useParams, useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Tooltip, TextField, FormControl, MenuItem, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
import ActionButtonEdit from 'components/Estoque/Solicitacao/EDIT/ActionButtonEdit';
import ActionButtonsEditCreate from 'components/Estoque/Solicitacao/ActionButtonsCreate';
import { CircularProgress } from '@mui/material';
// ============================= INITIALSTATE ==============================
const initialState = {
    emissao: new Date(),
    necessidade: '',
    obra: '',
    projeto: '',
    observacao: '',
    itens: []
};

const EditarSolicitacao = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    const navigate = useNavigate();
    const { id } = useParams();
    // ============================= STATES =============================
    const [campos, setCampos] = useState([initialState]);
    const [openCreate, setOpenCreate] = useState(false);
    const [loading, setLoading] = useState(true);
    const [itens, setItens] = useState([]);
    const [listAllItens, setListAllItens] = useState([]);
    const [listAllObras, setListAllObras] = useState([]);
    const [listAllProjetos, setListAllProjetos] = useState([]);
    const [filtredProjetos, setFiltredProjetos] = useState([]);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 1 },
        { field: 'unidade', headerName: 'UN', flex: 0.5, sortable: false },
        { field: 'quantidade', headerName: 'QUANTIDADE', flex: 1 },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <ActionButtonEdit
                    setCampos={setCampos}
                    itens={itens}
                    setItens={setItens}
                    row={params.row}
                    atualizarCampoItem={atualizarCampoItem}
                    removerCampoItem={removerCampoItem}
                    listAllItens={listAllItens}
                />
            )
        }
    ];
    // ============================= FUNÇÕES =============================
    const handleFilterProjetos = (value) => {
        const newList = listAllProjetos.filter((el) => el.obra_id === value);
        setFiltredProjetos(newList);
    };

    const atualizarCampo = (indiceCampo, campo, valor) => {
        setCampos((prevCampos) => {
            if (campo === 'fornecedor') {
                const novosCampos = [...prevCampos];
                novosCampos[indiceCampo][campo] = parseInt(valor);
                return novosCampos;
            } else {
                const novosCampos = [...prevCampos];
                novosCampos[indiceCampo][campo] = valor;
                return novosCampos;
            }
        });
    };

    const adicionarCampoItem = (indiceCampo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            const arrItens = novosCampos[0].itens;
            const newItens = [
                ...arrItens,
                {
                    action: 'create',
                    item_id: '',
                    descricao: '',
                    quantidade: 0,
                    unidade: '',
                    indiceCampoItem: ''
                }
            ];
            novosCampos[indiceCampo].itens = newItens;
            return novosCampos;
        });
    };

    const removerCampoItem = (indiceCampo, indiceCampoItem) => {
        // remover o item do data grid
        setItens((prevItens) => {
            const novosItens = [...prevItens];
            const teste = novosItens.find((item) => item.indiceCampoItem === indiceCampoItem);
            const filtredItens = novosItens.filter((item) => item !== teste);
            return filtredItens;
        });
        // achar o item deletado e colocar a action 'delete'
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            const indiceItem = novosCampos[0].itens.findIndex((item) => item.indiceCampoItem === indiceCampoItem);

            if (indiceItem !== -1) {
                const item = novosCampos[0].itens[indiceItem];
                item.action = 'delete';
            }

            return novosCampos;
        });
        if (openCreate) {
            setOpenCreate(false);
        }
    };

    const atualizarCampoItem = (indiceCampo, indiceCampoItem, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].itens[indiceCampoItem][campo] = valor;
            return novosCampos;
        });
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const handleClose = () => {
        setOpenCreate(false);
    };

    const handleCreate = (indiceCampo) => {
        adicionarCampoItem(indiceCampo);
    };

    const handleOpenAndCreateCampoItem = (indiceCampo) => {
        setOpenCreate(true);
        adicionarCampoItem(indiceCampo);
    };

    const getSolicitacaoById = async (solicitacaoId) => {
        const [itens, obras, projetos] = await Promise.all([api.getAllItens(), api.getAllObra(), api.getAllProjeto()]);
        setListAllItens(itens.data.itens);
        setListAllObras(obras.data.obras);
        setListAllProjetos(projetos.data.projetos);

        // --- get da solicitação --- //

        const response = await api.getSolicitacaoById(solicitacaoId);
        // ==== montando objeto de cada item ====
        const data = response.data.data;
        data.item_id.forEach((item) => {
            item.descricao = item.item.descricao;
            item.unidade = item.item.unidade_medida.descricao;
            item.action = 'update';
            delete item.item;
        });
        // filtro de projetos por obra
        const newList = projetos.data.projetos.filter((el) => el.obra_id === data.obra_id);
        setFiltredProjetos(newList);
        //==== montando body de campos ====
        const objeto = [
            {
                emissao: data.data,
                necessidade: data.necessidade,
                obra: data.obra.id,
                projeto: data.projeto.id,
                observacao: data.observacao,
                itens: data.item_id
            }
        ];
        setCampos(objeto);
        setItens(data.item_id);
        setLoading(false);
    };

    const getDate = () => {
        const date = new Date();
        let currentDay = String(date.getDate()).padStart(2, '0');
        let currentMonth = String(date.getMonth() + 1).padStart(2, '0');
        let currentYear = date.getFullYear();
        let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[0].movimento = currentDate;
            return novosCampos;
        });
    };

    useEffect(() => {
        getSolicitacaoById(id);
        getDate();
    }, [id]);

    //====== SUBMIT ======
    const handleSubmit = async (e, campo) => {
        e.preventDefault();
        const novosItens = [...itens];
        let arrDelete = [];

        // compara os dois estados, e adiciona em itens os "itens" que foram deletados, mas estão armazenados em campos[0].itens
        // cria um array temporário de deletados para comparar a action, caso seja "delete" da push
        // crio um novo array de itens com os dados atualizados e comparados com o main state de itens do campos[0].itens
        campos[0].itens.forEach((itemCampos) => {
            const itemExistente = novosItens.find(
                (item) => item.id === itemCampos.id && item.indiceCampoItem === itemCampos.indiceCampoItem
            );

            if (!itemExistente || itemExistente === undefined) {
                arrDelete.push(itemCampos);
            }

            if (itemCampos.action === 'delete') {
                arrDelete.push(itemCampos);
            }
        });

        // se o array de deletados tiver item, adiciona no itens
        if (arrDelete.length > 0) {
            novosItens.push(...arrDelete);
        }
        let a = [...novosItens];

        // passa as actions armazenadas no itens de campos[0].itens para o state itens
        // caso a action seja de "create", tiro o campo id antes de enviar pro backend
        for (let i = 0; i < itens.length; i++) {
            const itemItens = itens[i];

            const itemCampos = campos[0].itens.find(
                (item) => item.item_id === itemItens.item_id && item.indiceCampo === itemItens.indiceCampoItem
            );

            if (itemCampos) {
                itemItens.action = itemCampos.action;
            }

            if (itemItens.action === 'create') {
                delete itemItens.id;
            }
        }

        // pega todos os itens de campos "reais" (que não foram iniciados mas não concluiu a criação no modal)
        // verifica o id do item campos com o item de itens
        // fix na action update
        const teste = campos[0].itens
            .filter((k) => k.descricao !== '')
            .map((el) => {
                const sameId = a.find((item) => item.id === el.id);
                if (!sameId) return;
                const objeto = { ...sameId, solicitacao_id: parseInt(id) };
                if (!objeto.id && objeto.action === 'delete') {
                    return;
                }
                if (!objeto.id && objeto.action === 'update') {
                    objeto.action = 'create';
                }
                if (objeto.action === 'delete') {
                    a.push(objeto);
                }
                return objeto;
            });
        const payloadItens = teste.filter((item) => item !== undefined);
        const data = { entrada: campo, itens: payloadItens };

        try {
            await api.updateSolicitacao(id, data.entrada, data.itens);
            setCampos(undefined);
            setCampos([
                {
                    emissao: new Date(),
                    necessidade: '',
                    obra: '',
                    projeto: '',
                    observacao: '',
                    itens: []
                }
            ]);
            setItens([]);
            setOpenCreate(false);
            navigate('/main/solicitacao');
            toast.success('Solicitação Atualizada');
        } catch (error) {
            toast.error(`Erro ao atualizar Solicitação: ${error.response.data.message}`);
        }
    };

    return (
        <MainCard title="Editar Solicitação">
            <Grid container spacing={gridSpacing}>
                {loading && (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '2em' }}>
                        <CircularProgress />
                    </Box>
                )}
                <Box sx={{ width: '80%', margin: '0 auto' }}>
                    {!loading && (
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginLeft: '1em', marginTop: '1em' }}>
                            {campos.map((campo, indiceCampo) => (
                                <Box key={indiceCampo}>
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '1em',
                                                width: '100%',
                                                marginBottom: '1em'
                                            }}
                                        >
                                            <FormControl fullWidth>
                                                <TextField
                                                    type="date"
                                                    label="Emissão"
                                                    value={campo.emissao}
                                                    disabled
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <TextField
                                                    type="date"
                                                    value={campo.necessidade}
                                                    onChange={(e) => atualizarCampo(indiceCampo, 'necessidade', e.target.value)}
                                                    label="Necessidade"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <TextField
                                                    select
                                                    value={campo.obra}
                                                    label="Obra"
                                                    onChange={(e) => {
                                                        atualizarCampo(indiceCampo, 'obra', e.target.value),
                                                            handleFilterProjetos(e.target.value);
                                                    }}
                                                >
                                                    {listAllObras.map((obra) => (
                                                        <MenuItem key={obra.id} value={obra.id}>
                                                            {obra.descricao}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <TextField
                                                    select
                                                    value={campo.projeto}
                                                    label="Projeto"
                                                    onChange={(e) => atualizarCampo(indiceCampo, 'projeto', e.target.value)}
                                                >
                                                    {filtredProjetos.map((projeto) => (
                                                        <MenuItem key={projeto.id} value={projeto.id}>
                                                            {projeto.descricao}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                        </Box>
                                        <Box>
                                            <FormControl sx={{ width: '50%' }}>
                                                <TextField
                                                    multiline
                                                    minRows={3}
                                                    placeholder="Observação"
                                                    label="Observação"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={campo.observacao}
                                                    onChange={(e) => atualizarCampo(indiceCampo, 'observacao', e.target.value)}
                                                />
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: '100%', marginTop: '2em', borderTop: '2px dashed #eee' }}>
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '1em' }}>
                                            <Box>
                                                <Typography variant="h4">Relação de itens</Typography>
                                            </Box>
                                            <Box>
                                                <Tooltip title="Adicionar item">
                                                    <Button
                                                        variant="contained"
                                                        color="success"
                                                        onClick={(e) => handleOpenAndCreateCampoItem(indiceCampo)}
                                                    >
                                                        <AddIcon />
                                                    </Button>
                                                </Tooltip>
                                            </Box>
                                        </Box>

                                        <Box sx={{ marginTop: '2em', display: 'flex', flexDirection: 'column', height: '300px' }}>
                                            <DataGrid
                                                rows={itens}
                                                columns={columns}
                                                hideFooter={true}
                                                onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                                            />
                                            <Box
                                                sx={{
                                                    width: '40%',
                                                    marginLeft: 'auto',
                                                    marginTop: '1em',
                                                    display: 'flex',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <Tooltip title="Salvar entrada">
                                                    <Button variant="contained" color="success" onClick={(e) => handleSubmit(e, campo)}>
                                                        <SaveIcon />
                                                    </Button>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </Box>
                                    {campos[0]?.itens.map((item, indiceCampoItem) => (
                                        <ActionButtonsEditCreate
                                            key={indiceCampoItem}
                                            open={openCreate}
                                            handleClose={handleClose}
                                            itens={itens}
                                            setItens={setItens}
                                            campos={campos}
                                            setCampos={setCampos}
                                            adicionarCampoItem={adicionarCampoItem}
                                            atualizarCampoItem={atualizarCampoItem}
                                            removerCampoItem={removerCampoItem}
                                            indiceCampoItem={indiceCampoItem}
                                            item={item}
                                            listAllItens={listAllItens}
                                            handleCreate={handleCreate}
                                        />
                                    ))}
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
            </Grid>
        </MainCard>
    );
};

export default EditarSolicitacao;
