import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';

const TabelaSeparacaoMateriais = ({ saldoItem, handleChange, separacaoPayload, itens, rest }) => {
    const [itemEditado, setItemEditado] = useState(null);
    const [valorEditadoAnterior, setValorEditadoAnterior] = useState(null);

    const handleQuantidadeSeparadaChange = (itemId, almoxarifadoId, localizacaoId, event) => {
        const newQuantidadeSeparada = event.target.value;
        const quantidadeSeparada = isNaN(parseInt(newQuantidadeSeparada)) ? '' : parseInt(newQuantidadeSeparada);

        // if (quantidadeSeparada > rest) {
        //     toast.error('Quantidade Separada maior que a quantidade solicitada');
        //     return;
        // }

        const novosItens = [...separacaoPayload.itens];
        const index = novosItens.findIndex(
            (el) => el.item.id === itemId && el.almoxarifado_id === almoxarifadoId && el.localizacao_id === localizacaoId
        );

        if (index !== -1) {
            novosItens[index].quantidade_separado = quantidadeSeparada;
        }

        setValorEditadoAnterior(quantidadeSeparada); // Atualize o valor editado anterior
        setItemEditado({
            itemId,
            almoxarifadoId,
            localizacaoId,
            quantidadeSeparada
        });

        handleChange(novosItens);
    };
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell align="right">UN</TableCell>
                        <TableCell align="right">Almoxarifado</TableCell>
                        <TableCell align="right">Localizacao</TableCell>
                        <TableCell align="right">Saldo</TableCell>
                        <TableCell align="right" sx={{ paddingRight: '120px' }}>
                            Saldo Separado
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {saldoItem.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{item?.item?.descricao}</TableCell>
                            <TableCell align="right">{item?.item?.unidade_medida?.descricao}</TableCell>
                            <TableCell align="right">{item?.almoxarifado?.descricao}</TableCell>
                            <TableCell align="right">{item?.localizacao?.descricao}</TableCell>
                            <TableCell align="right">{item?.quantidade_disponivel}</TableCell>

                            <TableCell align="right">
                                {itemEditado &&
                                itemEditado.itemId === item.item_id &&
                                itemEditado.almoxarifadoId === item.almoxarifado_id &&
                                itemEditado.localizacaoId === item.localizacao_id ? (
                                    <TextField
                                        label="Quantidade"
                                        value={
                                            itemEditado &&
                                            itemEditado.itemId === item.item_id &&
                                            itemEditado.almoxarifadoId === item.almoxarifado_id &&
                                            itemEditado.localizacaoId === item.localizacao_id
                                                ? itemEditado.quantidadeSeparada
                                                : valorEditadoAnterior !== null
                                                ? valorEditadoAnterior
                                                : ''
                                        }
                                        onChange={(e) =>
                                            handleQuantidadeSeparadaChange(item.item_id, item?.almoxarifado_id, item?.localizacao_id, e)
                                        }
                                    />
                                ) : (
                                    <>
                                        {separacaoPayload.itens.find(
                                            (el) =>
                                                el.item.id === item.item_id &&
                                                el.almoxarifado_id === item.almoxarifado_id &&
                                                el.localizacao_id === item.localizacao_id
                                        )?.quantidade_separado || ''}
                                        <Button
                                            variant="contained"
                                            sx={{ marginLeft: '1em' }}
                                            onClick={() =>
                                                setItemEditado({
                                                    itemId: item.item_id,
                                                    almoxarifadoId: item.almoxarifado_id,
                                                    localizacaoId: item.localizacao_id,
                                                    quantidadeSeparada:
                                                        separacaoPayload.itens.find(
                                                            (el) =>
                                                                el.item.id === item.item_id &&
                                                                el.almoxarifado_id === item.almoxarifado_id &&
                                                                el.localizacao_id === item.localizacao_id
                                                        )?.quantidade_separado || 0
                                                })
                                            }
                                        >
                                            <EditIcon sx={{ marginRight: '5px', paddingRight: '3px' }} />
                                        </Button>
                                    </>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TabelaSeparacaoMateriais;
