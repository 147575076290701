import React from 'react';
import { useApi } from 'Service/axios';
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { FormBox } from './style';
import useScriptRef from 'hooks/useScriptRef';
import { useTheme } from '@mui/material/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InputMask from 'react-input-mask';
import { removeMask } from 'utils/removeMask';
import { toast } from 'react-toastify';

const ActionButtonsFornecedor = ({ row, getFornecedores, ...others }) => {
    /////////////////////////////// INSTANCIAS /////////////////////////
    const theme = useTheme();
    const api = useApi();
    const scriptedRef = useScriptRef();
    /////////////////////////////// STATES /////////////////////////
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [ativoRadio, setAtivoRadio] = useState('0');
    const [loading, setLoading] = useState(false);
    const estados = [
        { id: 1, descricao: 'AC' },
        { id: 2, descricao: 'AL' },
        { id: 3, descricao: 'AP' },
        { id: 4, descricao: 'AM' },
        { id: 5, descricao: 'BA' },
        { id: 6, descricao: 'CE' },
        { id: 7, descricao: 'DF' },
        { id: 8, descricao: 'ES' },
        { id: 9, descricao: 'GO' },
        { id: 10, descricao: 'MA' },
        { id: 11, descricao: 'MT' },
        { id: 12, descricao: 'MS' },
        { id: 13, descricao: 'MG' },
        { id: 14, descricao: 'PA' },
        { id: 15, descricao: 'PB' },
        { id: 16, descricao: 'PR' },
        { id: 17, descricao: 'PE' },
        { id: 18, descricao: 'PI' },
        { id: 19, descricao: 'RJ' },
        { id: 20, descricao: 'RN' },
        { id: 21, descricao: 'RS' },
        { id: 22, descricao: 'RO' },
        { id: 23, descricao: 'RR' },
        { id: 24, descricao: 'SC' },
        { id: 25, descricao: 'SP' },
        { id: 26, descricao: 'SE' },
        { id: 27, descricao: 'TO' }
    ];
    /////////////////////////////// FUNÇÕES /////////////////////////
    const handleOpen = async () => {
        setOpen(true);
    };

    const handleCepChange = async (event, changeValue) => {
        const cep = removeMask(event.target.value);

        if (cep.length === 8) {
            try {
                const cepData = await api.getCepLocation(cep);

                if (cepData) {
                    const { bairro, localidade, logradouro, uf } = cepData?.data;
                    changeValue('bairro', bairro);
                    changeValue('cidade', localidade);
                    changeValue('endereco', logradouro);
                    changeValue('estado', uf);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleChangeRadio = (e) => {
        const value = e.target.value;
        setAtivoRadio(value);
    };

    const clearValues = (values) => {
        values.cnpj = '';
        values.nome_fantasia = '';
        values.email = '';
        values.telefone_comercial = '';
        values.contato = '';
        values.telefone_celular = '';
        values.cep = '';
        values.endereco = '';
        values.numero = '';
        values.complemento = '';
        values.bairro = '';
        values.cidade = '';
        values.estado = '';
        values.observacao = '';
        values.ativo = '';
    };

    const handleUpdate = async (values) => {
        try {
            setLoading(true);
            values.ativo = ativoRadio === '0' ? true : false;
            await api.updateFornecedor(row.id, values);
            clearValues(values);
            getFornecedores();
            setOpen(false);
            setLoading(false);
            toast.success('Fornecedor atualizado!');
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    };

    const handleDelete = async (fornecedorId) => {
        try {
            await api.deleteFornecedor(fornecedorId);
            getFornecedores();
            setOpenDialog(false);
            toast.success('Fornecedor excluído!');
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <Box>
            <Dialog open={openDialog}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>
                    <Typography>Tem certeza que deseja excluir o fornecedor?</Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex' }}>
                        <Button color="error" onClick={() => setOpenDialog(false)}>
                            Cancelar
                        </Button>
                        <Button onClick={() => handleDelete(row.id)}>Confirmar</Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <Box sx={{ width: '1000px', padding: '.75em', height: '100%' }}>
                    <Formik
                        initialValues={{
                            cnpj: row.cnpj,
                            nome_fantasia: row.nome_fantasia,
                            email: row.email,
                            telefone_comercial: row.telefone_comercial,
                            contato: row.contato,
                            telefone_celular: row.telefone_celular,
                            cep: row.cep,
                            endereco: row.endereco,
                            numero: row.numero,
                            complemento: row.complemento,
                            bairro: row.bairro,
                            cidade: row.cidade,
                            estado: row.estado,
                            observacao: row.observacao,
                            ativo: row.ativo,
                            submit: null
                        }}
                        validationSchema={Yup.object().shape({
                            cnpj: Yup.string().required('Descricao é obrigatorio')
                        })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                if (scriptedRef.current) {
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                }
                                values.telefone_celular = removeMask(values.telefone_celular);
                                values.telefone_comercial = removeMask(values.telefone_comercial);
                                values.cnpj = removeMask(values.cnpj);
                                values.cep = removeMask(values.cep);
                                await handleUpdate(values);
                            } catch (err) {
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err.msg });
                                    setSubmitting(false);
                                }
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                                {...others}
                                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        height: '80%'
                                    }}
                                >
                                    <Typography variant="h3">Fornecedor</Typography>
                                    <Typography variant="h4" sx={{ margin: '1em 0 1em 0' }}>
                                        Geral
                                    </Typography>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <FormBox>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.cnpj && errors.cnpj)}
                                                sx={{ ...theme.typography.customInput }}
                                            >
                                                <InputLabel htmlFor="outlined-adornment-cnpj-register">CNPJ</InputLabel>
                                                <InputMask
                                                    mask="99.999.999/9999-99"
                                                    value={values.cnpj}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                >
                                                    {(inputProps) => (
                                                        <OutlinedInput
                                                            id="outlined-adornment-cnpj-register"
                                                            type="text"
                                                            name="cnpj"
                                                            inputProps={inputProps}
                                                        />
                                                    )}
                                                </InputMask>

                                                {touched.cnpj && errors.cnpj && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.cnpj}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </FormBox>
                                        <FormBox sx={{ width: '79%' }}>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.nome_fantasia && errors.nome_fantasia)}
                                                sx={{ ...theme.typography.customInput }}
                                            >
                                                <InputLabel htmlFor="outlined-adornment-nome_fantasia-register">Nome Fantasia</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-nome_fantasia-register"
                                                    type="text"
                                                    value={values.nome_fantasia}
                                                    name="nome_fantasia"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    inputProps={{}}
                                                />
                                                {touched.nome_fantasia && errors.nome_fantasia && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.nome_fantasia}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </FormBox>
                                    </Box>
                                    <Typography sx={{ marginTop: '.5em', fontWeight: 'bold' }}>Informações</Typography>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <FormBox sx={{ width: '79%' }}>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.email && errors.email)}
                                                sx={{ ...theme.typography.customInput }}
                                            >
                                                <InputLabel htmlFor="outlined-adornment-email-register">Email</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-email-register"
                                                    type="text"
                                                    value={values.email}
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    inputProps={{}}
                                                />
                                                {touched.email && errors.email && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.email}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </FormBox>
                                        <FormBox>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.telefone_comercial && errors.telefone_comercial)}
                                                sx={{ ...theme.typography.customInput }}
                                            >
                                                <InputLabel htmlFor="outlined-adornment-telefone_comercial-register">
                                                    Telefone Comercial
                                                </InputLabel>
                                                <InputMask
                                                    mask="(99) 9999-9999"
                                                    value={values.telefone_comercial}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                >
                                                    {(inputProps) => (
                                                        <OutlinedInput
                                                            id="outlined-adornment-telefone_comercial-register"
                                                            type="text"
                                                            name="telefone_comercial"
                                                            inputProps={inputProps}
                                                        />
                                                    )}
                                                </InputMask>
                                                {touched.telefone_comercial && errors.telefone_comercial && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.telefone_comercial}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </FormBox>
                                    </Box>

                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <FormBox sx={{ width: '79%' }}>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.contato && errors.contato)}
                                                sx={{ ...theme.typography.customInput }}
                                            >
                                                <InputLabel htmlFor="outlined-adornment-contato-register">Contato</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-contato-register"
                                                    type="text"
                                                    value={values.contato}
                                                    name="contato"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    inputProps={{}}
                                                />
                                                {touched.contato && errors.contato && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.contato}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </FormBox>
                                        <FormBox>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.telefone_celular && errors.telefone_celular)}
                                                sx={{ ...theme.typography.customInput }}
                                            >
                                                <InputLabel htmlFor="outlined-adornment-telefone_celular-register">
                                                    Telefone Celular
                                                </InputLabel>
                                                <InputMask
                                                    mask="(99) 99999-9999"
                                                    value={values.telefone_celular}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                >
                                                    {(inputProps) => (
                                                        <OutlinedInput
                                                            id="outlined-adornment-telefone_celular-register"
                                                            type="text"
                                                            name="telefone_celular"
                                                            inputProps={inputProps}
                                                        />
                                                    )}
                                                </InputMask>
                                                {touched.telefone_celular && errors.telefone_celular && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.telefone_celular}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </FormBox>
                                    </Box>
                                    <Typography sx={{ marginTop: '.5em', fontWeight: 'bold' }}>Endereço</Typography>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                        <FormBox>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.cep && errors.cep)}
                                                sx={{ ...theme.typography.customInput }}
                                            >
                                                <InputLabel htmlFor="outlined-adornment-cep-register">CEP</InputLabel>
                                                <InputMask
                                                    mask="99999.999"
                                                    value={values.cep}
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e), handleCepChange(e, setFieldValue);
                                                    }}
                                                >
                                                    {(inputProps) => (
                                                        <OutlinedInput
                                                            id="outlined-adornment-cep-register"
                                                            type="text"
                                                            name="cep"
                                                            inputProps={inputProps}
                                                        />
                                                    )}
                                                </InputMask>
                                                {touched.cep && errors.cep && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.cep}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </FormBox>
                                        <FormBox sx={{ width: '55%' }}>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.endereco && errors.endereco)}
                                                sx={{ ...theme.typography.customInput }}
                                            >
                                                <InputLabel htmlFor="outlined-adornment-endereco-register">Endereço</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-endereco-register"
                                                    type="text"
                                                    value={values.endereco}
                                                    name="endereco"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    inputProps={{}}
                                                />
                                                {touched.endereco && errors.endereco && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.endereco}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </FormBox>
                                        <FormBox>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.numero && errors.numero)}
                                                sx={{ ...theme.typography.customInput }}
                                            >
                                                <InputLabel htmlFor="outlined-adornment-numero-register">Número</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-numero-register"
                                                    type="number"
                                                    value={values.numero}
                                                    name="numero"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    inputProps={{}}
                                                />
                                                {touched.numero && errors.numero && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.numero}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </FormBox>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',

                                            alignItems: 'center',
                                            gap: '1em'
                                        }}
                                    >
                                        <FormBox>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.complemento && errors.complemento)}
                                                sx={{ ...theme.typography.customInput }}
                                            >
                                                <InputLabel htmlFor="outlined-adornment-complemento-register">Complemento</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-complemento-register"
                                                    type="text"
                                                    value={values.complemento}
                                                    name="complemento"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    inputProps={{}}
                                                />
                                                {touched.complemento && errors.complemento && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.complemento}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </FormBox>
                                        <FormBox>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.bairro && errors.bairro)}
                                                sx={{ ...theme.typography.customInput }}
                                            >
                                                <InputLabel htmlFor="outlined-adornment-bairro-register">Bairro</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-bairro-register"
                                                    type="text"
                                                    value={values.bairro}
                                                    name="bairro"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    inputProps={{}}
                                                />
                                                {touched.bairro && errors.bairro && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.bairro}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </FormBox>
                                        <FormBox>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.cidade && errors.cidade)}
                                                sx={{ ...theme.typography.customInput }}
                                            >
                                                <InputLabel htmlFor="outlined-adornment-cidade-register">Cidade</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-cidade-register"
                                                    type="text"
                                                    value={values.cidade}
                                                    name="cidade"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    inputProps={{}}
                                                />
                                                {touched.cidade && errors.cidade && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.cidade}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </FormBox>

                                        <FormBox sx={{ width: '20%' }}>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.estado && errors.estado)}
                                                sx={{ ...theme.typography.customInput }}
                                            >
                                                {' '}
                                                <TextField
                                                    id="estado"
                                                    name="estado"
                                                    select
                                                    value={values.estado}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    label="Estado"
                                                    SelectProps={{
                                                        native: false
                                                    }}
                                                >
                                                    {estados.map((estado) => (
                                                        <MenuItem key={estado.id} value={estado.descricao}>
                                                            {estado.descricao}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                {touched.estado && errors.estado && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.estado}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </FormBox>
                                    </Box>
                                    <Box sx={{ display: 'flex', width: '100%' }}>
                                        <FormBox sx={{ width: '60%', marginRight: '1em' }}>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.observacao && errors.observacao)}
                                                sx={{ ...theme.typography.customInput }}
                                            >
                                                <TextField
                                                    id="observacao"
                                                    name="observacao"
                                                    placeholder="Observação"
                                                    multiline
                                                    rows={4}
                                                    value={values.observacao}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {touched.observacao && errors.observacao && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.observacao}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </FormBox>
                                        <FormBox>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.ativo && errors.ativo)}
                                                sx={{ ...theme.typography.customInput }}
                                            >
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={ativoRadio}
                                                    onChange={(e) => handleChangeRadio(e)}
                                                >
                                                    <FormControlLabel value={'0'} control={<Radio />} label="Ativo" />
                                                    <FormControlLabel value={'1'} control={<Radio />} label="Inativo" />
                                                </RadioGroup>
                                            </FormControl>
                                        </FormBox>
                                    </Box>
                                </Box>

                                {errors.submit && (
                                    <Box sx={{ mt: 3 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}

                                {/* BOTÕES */}
                                <Box sx={{ mt: 2, width: '20%', marginLeft: 'auto' }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={loading}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            {loading ? 'Aguarde' : 'Editar'}
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Drawer>
            <Box>
                <Tooltip title="Editar Fornecedor">
                    <Button onClick={handleOpen}>
                        <EditIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Remover Fornecedor">
                    <Button onClick={() => setOpenDialog(true)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
            </Box>
        </Box>
    );
};

export default ActionButtonsFornecedor;
