import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// API
import { useApi } from 'Service/axios';

// components
import { FormBox } from './style';
// router
import { useParams, Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const EditarUnidadeForm = ({ ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const { id } = useParams();
    const navigate = useNavigate();
    const api = useApi();
    const [submitError, setSubmitError] = useState(null);
    const [unidade, setUnidade] = useState(null);

    const getUnidade = async (id) => {
        const response = await api.getUnitById(id);
        setUnidade(response.data.unidade);
    };

    function isAnyFieldEmpty(values) {
        const fieldsToCheck = ['descricao', 'unidade_medida'];

        for (const field of fieldsToCheck) {
            if (values[field].trim() === '') {
                return true;
            }
        }

        return false;
    }

    const handleRegister = async (values) => {
        // monta um objeto apenas com valores !== de ""
        const filteredValues = Object.entries(values).reduce((acc, [key, value]) => {
            if (value !== '') {
                acc[key] = value;
            }
            return acc;
        }, {});

        // monta o payload final
        const unidadeObject = filteredValues;
        try {
            const response = await api.updateUnit(id, unidadeObject);
            toast.success('Editado com sucesso!');
            navigate('/main/unidade');
        } catch (error) {
            if (error.response.data.erro) toast.error(error.response.data.erro);
        }
    };

    useEffect(() => {
        getUnidade(id);
    }, []);

    return (
        <>
            {unidade !== null && (
                <Formik
                    initialValues={{
                        descricao: unidade?.descricao,
                        unidade_medida: unidade?.unidade_medida,
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        descricao: Yup.string().required('Descrição é obrigatório'),
                        unidade_medida: Yup.string().required('Unidade é obrigatório')
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                            handleRegister(values);
                        } catch (err) {
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.msg });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit} {...others} style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.descricao && errors.descricao)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-descricao-register">Descrição</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-descricao-register"
                                        type="text"
                                        value={values.descricao}
                                        name="descricao"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.descricao && errors.descricao && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.descricao}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.unidade_medida && errors.unidade_medida)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-unidade_medida-register">Unidade</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-unidade_medida-register"
                                        type="text"
                                        value={values.unidade_medida}
                                        name="unidade_medida"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.unidade_medida && errors.unidade_medida && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.unidade_medida}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            {/* {!submitError && errors.submit ? (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            ) : (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{submitError}</FormHelperText>
                                </Box>
                            )} */}

                            {/* BOTÕES */}
                            <Box sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <Link to={'/main/unidade'}>
                                    <Button>Voltar</Button>
                                </Link>
                                {/* <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting || isAnyFieldEmpty(values)}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        {isSubmitting || isAnyFieldEmpty(values)
                                            ? 'Descrição da Uniade está faltando'
                                            : 'Atualizar Unidade'}
                                    </Button>
                                </AnimateButton> */}
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting || isAnyFieldEmpty(values)}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        {isSubmitting || isAnyFieldEmpty(values)
                                            ? 'Descrição da Uniade está faltando'
                                            : 'Atualizar Unidade'}
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default EditarUnidadeForm;
