import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, TextField, Typography, Box, InputLabel, Select, MenuItem, ButtonGroup, FormLabel } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useApi } from 'Service/axios';
import EditarItemForm from '../../../components/Item/EditarItem/EditarItem';

const EditItem = () => {
    const { id } = useParams();
    const api = useApi();
    const [item, setItem] = useState(null);

    const getItem = async (id) => {
        const response = await api.getItemById(id);
        setItem(response.data.item);
    };

    useEffect(() => {
        getItem(id);
    }, [id]);

    return (
        <MainCard title="Editar Item">
            <Grid container spacing={gridSpacing}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <EditarItemForm item={item} />
                </Box>
            </Grid>
        </MainCard>
    );
};

export default EditItem;
