import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// API
import { useApi } from 'Service/axios';

// components
import { FormBox } from './style';
import { toast } from 'react-toastify';

const CadastroCategoria = ({ getAllCategorias, ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const api = useApi();
    const [submitError, setSubmitError] = useState(null);

    function isAnyFieldEmpty(values) {
        const fieldsToCheck = ['descricao'];

        for (const field of fieldsToCheck) {
            if (values[field].trim() === '') {
                return true;
            }
        }

        return false;
    }

    const handleRegister = async (values) => {
        // monta um objeto apenas com valores !== de ""
        const filteredValues = Object.entries(values).reduce((acc, [key, value]) => {
            if (value !== '') {
                acc[key] = value;
            }
            return acc;
        }, {});

        // monta o payload final
        const categoria = filteredValues;
        try {
            const response = await api.createCategory(categoria);
            toast.success('Cadastrado com suecesso!');
            getAllCategorias();
        } catch (error) {
            toast.error(error.response.data.erro);
            setSubmitError(error.response.data.erro);
        }
    };

    return (
        <Formik
            initialValues={{
                descricao: '',
                submit: null
            }}
            validationSchema={Yup.object().shape({
                descricao: Yup.string().required('Descrição é obrigatório')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    if (scriptedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                    }
                    handleRegister(values);
                    values.descricao = '';
                } catch (err) {
                    if (scriptedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.msg });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit} {...others} style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormBox>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.descricao && errors.descricao)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-descricao-register">Descrição</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-descricao-register"
                                type="text"
                                value={values.descricao}
                                name="descricao"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.descricao && errors.descricao && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.descricao}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </FormBox>

                    {!submitError && errors.submit ? (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                    ) : (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>{submitError}</FormHelperText>
                        </Box>
                    )}

                    {/* BOTÕES */}
                    <Box sx={{ mt: 2 }}>
                        <AnimateButton>
                            <Button
                                sx={{ marginBottom: '3em' }}
                                disableElevation
                                disabled={isSubmitting || isAnyFieldEmpty(values)}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {isSubmitting || isAnyFieldEmpty(values) ? 'Descrição da categoria está faltando' : 'Cadastrar Categoria'}
                            </Button>
                        </AnimateButton>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default CadastroCategoria;
