import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Select,
    Typography,
    MenuItem
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';
import InputMask from 'react-input-mask';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Context } from 'context/UserContext';
import { useApi } from 'Service/axios';
import { useNavigate, useParams } from 'react-router';
import { FormBox } from './style';
import { useContext } from 'react';
import { removeMask } from 'utils/removeMask';
import { toast } from 'react-toastify';

const EditarProjetoForm = ({ ...others }) => {
    const theme = useTheme();
    const api = useApi();
    const navigate = useNavigate();
    const { id } = useParams();
    const scriptedRef = useScriptRef();
    const [projeto, setProjeto] = useState(null);
    const [allObra, setAllObra] = useState([]);
    const [submitError, setSubmitError] = useState(null);

    const getObras = async () => {
        const response = await api.getAllObra();
        setAllObra(response.data.obras);
    };

    const getProjeto = async (id) => {
        const response = await api.getProjetoById(id);
        setProjeto(response.data.projeto);
    };

    const handleRegister = async (values) => {
        // monta um objeto apenas com valores !== de ""
        const filteredValues = Object.entries(values).reduce((acc, [key, value]) => {
            if (value !== '') {
                acc[key] = value;
            }
            return acc;
        }, {});

        const projeto = filteredValues;

        try {
            const response = await api.updateProjeto(id, projeto);
            toast.success('Editado com sucesso!');
            navigate('/main/projetos');
        } catch (error) {
            toast.success(error.response.mensagem);
            setSubmitError(error.response.mensagem);
        }
    };

    const clearValues = (values) => {
        values.descricao = '';
        values.obra_id = '';
        values.responsavel = '';
    };

    useEffect(() => {
        getProjeto(id);
        getObras();
    }, [id]);

    return (
        <>
            {projeto !== null && (
                <Formik
                    initialValues={{
                        descricao: projeto.descricao,
                        obra_id: projeto.obra.id,
                        responsavel: projeto.responsavel,
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        descricao: Yup.string().required('Descrição é obrigatório'),
                        obra_id: Yup.string().required('Obra é obrigatório'),
                        responsavel: Yup.string().required('Responsável é obrigatório')
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                            handleRegister(values);
                            clearValues(values);
                        } catch (err) {
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.msg });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit} {...others} style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.descricao && errors.descricao)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-descricao-register">Descrição</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-descricao-register"
                                        type="text"
                                        value={values.descricao}
                                        name="descricao"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.descricao && errors.descricao && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.descricao}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.obra_id && errors.obra_id)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-obra_id-register">Obra</InputLabel>
                                    <Select
                                        style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                        id="outlined-adornment-obra_id-register"
                                        type="text"
                                        value={values.obra_id}
                                        name="obra_id"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    >
                                        {allObra.length > 0 &&
                                            allObra.map((obra) => (
                                                <MenuItem key={obra.id} value={obra.id}>
                                                    {obra.descricao}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {touched.obra_id && errors.obra_id && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.obra_id}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            <FormControl
                                fullWidth
                                error={Boolean(touched.responsavel && errors.responsavel)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <InputLabel htmlFor="outlined-adornment-responsavel-register">Responsavel</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-responsavel-register"
                                    type="text"
                                    value={values.responsavel}
                                    name="responsavel"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                />
                                {touched.responsavel && errors.responsavel && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.responsavel}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}

                            {/* BOTÕES */}
                            <Box sx={{ mt: 2 }}>
                                <AnimateButton>
                                    <Button
                                        sx={{ marginBottom: '3em' }}
                                        disableElevation
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        {isSubmitting ? 'Valores Obrigatórios faltando' : 'Editar Projeto'}
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default EditarProjetoForm;
