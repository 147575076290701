import React from 'react';
import { useApi } from 'Service/axios';
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControl,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const ActionButtonsCreate = ({
    open,
    handleClose,
    itens,
    setItens,
    adicionarCampoItem,
    atualizarCampoItem,
    removerCampoItem,
    indiceCampoItem,
    item,
    listAllItens,
    listAllAlmoxarifado,
    listAllLocalizacao,
    handleCreate
}) => {
    /////////////////////////////// INSTANCIAS /////////////////////////

    /////////////////////////////// STATES /////////////////////////
    const [openDialog, setOpenDialog] = useState(false);
    const [indexItem, setIndexItem] = useState(null);
    const [filtredLocations, setFiltredLoctions] = useState([]);
    /////////////////////////////// FUNÇÕES /////////////////////////
    const handleOpen = async () => {
        setOpen(true);
    };

    const handleSetFiltredLocations = (almoxarifadoId) => {
        const locations = listAllLocalizacao.filter((item) => parseInt(item.id_almoxarifado) === parseInt(almoxarifadoId));
        setFiltredLoctions(locations);
    };

    const handleChangeItem = (e) => {
        const objeto = e.target.value;
        console.log('DIAJDA', objeto);
        setIndexItem(objeto);
        atualizarCampoItem(0, indiceCampoItem, 'item_id', objeto.id);
        atualizarCampoItem(0, indiceCampoItem, 'descricao', objeto.descricao);
        atualizarCampoItem(0, indiceCampoItem, 'unidade', objeto.unidade_medida.descricao);
    };

    useEffect(() => {
        atualizarCampoItem(0, indiceCampoItem, 'indiceCampoItem', indiceCampoItem);
    }, []);

    return (
        <Box>
            <Dialog open={openDialog}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>
                    <Typography>Ao fechar todo o cadastro desse item sera cancelado, continuar?</Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex' }}>
                        <Button color="error" onClick={() => setOpenDialog(false)}>
                            Cancelar
                        </Button>
                        <Button
                            onClick={() => {
                                removerCampoItem(0, indiceCampoItem);
                                setOpenDialog(false);
                            }}
                        >
                            Confirmar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Drawer
                sx={{
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'transparent'
                    }
                }}
                anchor="right"
                open={open}
            >
                <Box sx={{ width: '450px', padding: '.75em', height: '100%' }}>
                    <Box>
                        <Box sx={{ marginTop: '1em', marginBottom: '2em' }}>
                            <Typography variant="h3">Incluir itens</Typography>
                        </Box>
                        <FormControl fullWidth>
                            <TextField select label="Item" value={item.id} onChange={(e) => handleChangeItem(e)}>
                                {listAllItens.map((i) => (
                                    <MenuItem key={i.id} value={i}>
                                        {i.descricao}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                        <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Quantidade"
                                    type="number"
                                    value={item.quantidade}
                                    onChange={(e) => atualizarCampoItem(0, indiceCampoItem, 'quantidade', parseFloat(e.target.value))}
                                />
                            </FormControl>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '2em' }}>
                            <Tooltip title="Fechar e cancelar">
                                <Button variant="contained" color="error" onClick={() => setOpenDialog(true)}>
                                    <CloseIcon />
                                </Button>
                            </Tooltip>
                            <Button
                                variant="contained"
                                onClick={(e) => {
                                    const ultimoItem = itens[itens.length - 1];
                                    const novoId = ultimoItem ? ultimoItem.id + 1 : 1;
                                    const novoItem = { ...item, id: novoId };
                                    setItens((prevItens) => [...prevItens, novoItem]);
                                    handleCreate(0);
                                }}
                            >
                                Salvar
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
};

export default ActionButtonsCreate;
