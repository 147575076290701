import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { ActionInput } from './components/ActionInput';
import { toast } from 'react-toastify';
export function FinalSeparation() {
    const { id } = useParams();
    const api = useApi();
    const [rows, setRows] = useState([]);
    const [load, setLoad] = useState(false);
    const [qnt, setQnt] = useState(null);
    const navigate = useNavigate();
    const getFinalSeparation = async () => {
        try {
            const { data } = await api.getFinalizacao(id); // Adicionado 'await' aqui
            const row = data.map((item, index) => ({
                ...item,
                id: item.id,
                item: item?.item?.descricao,
                qntseparada: item.quantidade_separado,
                rest: item.quantidadeLiberada.restante !== null ? item.quantidadeLiberada.restante : item.quantidade_separado,
                index: index,
                un: item.item?.unidade_medida_id,
                loc: item.localizacao_id,
                item_id: item.item.id
            }));
            setRows(row);
        } catch (error) {
            console.log('🚀 ~ getFinalSeparation ~ error:', error);
        }
    };

    const column = [
        { field: 'id', headerName: 'ID', flex: 0.5, sortable: false },
        { field: 'item', headerName: 'Item', flex: 1, sortable: false },
        { field: 'qntseparada', headerName: 'Quantidade separada', flex: 1, sortable: false },
        { field: 'rest', headerName: 'Restante', flex: 0.8, sortable: false },
        {
            field: 'action',
            headerName: 'Quantidade a liberar',
            flex: 0.8,
            sortable: false,
            renderCell: (params) => {
                return <ActionInput allRows={rows} currentRow={params.row} qnt={qnt} setQnt={setQnt} />;
            }
        }
    ];

    useEffect(() => {
        getFinalSeparation();
    }, []);

    const confirmSeparatin = async () => {
        setLoad(true);
        const idUser = localStorage.getItem('userIdTriunfo');

        const isValid = rows.every((item) => {
            const quantidadeLiberada = item?.quantidade || 0;
            const quantidadeSeparada = item?.qntseparada || 0;
            return quantidadeLiberada <= quantidadeSeparada;
        });

        if (!isValid) {
            alert('A Quantidade a liberar não pode ser maior que a quantidade separada!');
            setLoad(false);
            return;
        }

        const arrayOfItens = rows.map((row) => ({
            item_id: row.item_id,
            unidade_medida_id: row.un,
            quantidade: row.quantidade || 0,
            almoxarifado_id: row.almoxarifado_id,
            localizacao_id: row.loc,
            separacao_item_id: row.id
        }));

        const payload = {
            separacao_id: id,
            itens: arrayOfItens,
            responsavel_id: idUser
        };

        console.log('🚀 ~ confirmSeparatin ~ payload:', payload);
        try {
            await api.postFinalizacaoLiberacao(payload);
            setLoad(false);
            navigate(-1);
            toast.success(`Liberação do item ${id}, concluida com sucesso!`);
        } catch (error) {
            console.log('🚀 ~ confirmSeparatin ~ error:', error);
            toast.error(error.response.data.message);
            setLoad(false);
        }
    };

    return (
        <MainCard title="Liberar separação" sx={{ position: 'relative' }}>
            <Grid container spacing={gridSpacing} sx={{ flexDirection: 'column' }}>
                <DataGrid rows={rows} columns={column} hideFooter />
                <div style={{ display: 'flex', marginTop: '30px', justifyContent: 'flex-end' }}>
                    <Button variant="contained" onClick={confirmSeparatin} disabled={load}>
                        {load ? 'Carregando liberação...' : 'Liberar Quantidade'}
                    </Button>
                </div>
            </Grid>
        </MainCard>
    );
}
