import { Button, Grid, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import formater from 'utils/DateDDMMYYY';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';

const LiberacaoMaterial = () => {
    const api = useApi();
    const navigate = useNavigate();
    // ----- STATES ----- \\
    const [data, setData] = useState([]);
    const DataGridColumns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        {
            field: 'data',
            headerName: 'DATA',
            flex: 1,
            valueGetter: (params) => formater(params.row.data)
        },
        {
            field: 'necessidade',
            headerName: 'NECESSIDADE',
            flex: 1,
            valueGetter: (params) => formater(params.row.necessidade)
        },
        {
            field: 'status',
            headerName: 'STATUS LIB',
            flex: 1,
            valueGetter: (params) => params.row.status
        },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Abrir liberação">
                        <Button onClick={() => navigate(`/main/liberacao/formulario/${params.row.id}`)}>
                            <VisibilityIcon />
                        </Button>
                    </Tooltip>
                </Box>
            )
        }
    ];

    // ----- FUNCTIONS ----- \\
    const getPendingLiberacao = async () => {
        try {
            const { data } = await api.getPedingv2();
            const row = data.map((item) => {
                return {
                    id: item.id,
                    data: item.data,
                    necessidade: item.necessidade,
                    status: item.status
                };
            });
            setData(data);
        } catch (error) {
            console.log('🚀 ~ getPendingLiberacao ~ error:', error);
        }
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    useEffect(() => {
        getPendingLiberacao();
    }, []);

    return (
        <MainCard title="Liberação Material">
            <Grid container spacing={gridSpacing}>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        marginLeft: '1rem',
                        marginTop: '1rem'
                    }}
                >
                    <DataGrid
                        columns={DataGridColumns}
                        rows={data}
                        hideFooter={false}
                        onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                    />
                </Box>
            </Grid>
        </MainCard>
    );
};

export default LiberacaoMaterial;
