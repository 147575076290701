import { useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Tooltip, TextField, FormControl, MenuItem, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import ActionButtons from 'components/Estoque/Solicitacao/ActionButtons';
import ActionButtonsCreate from 'components/Estoque/Solicitacao/ActionButtonsCreate';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
// ============================= INITIALSTATE =============================
const initialState = {
    emissao: new Date(),
    necessidade: '',
    obra: '',
    projeto: '',
    observacao: '',
    itens: []
};

const NovaSolicitacao = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    const navigate = useNavigate();
    // ============================= STATES =============================
    const [campos, setCampos] = useState([initialState]);
    const [openCreate, setOpenCreate] = useState(false);
    const [listAllItens, setListAllItens] = useState([]);
    const [listAllObras, setListAllObras] = useState([]);
    const [listAllProjetos, setListAllProjetos] = useState([]);
    const [filtredProjetos, setFiltredProjetos] = useState([]);
    const [itens, setItens] = useState([]);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 1 },
        { field: 'unidade', headerName: 'UN', flex: 1 },
        { field: 'quantidade', headerName: 'QUANTIDADE', flex: 1 },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <ActionButtons setItens={setItens} row={params.row} removerCampoItem={removerCampoItem} listAllItens={listAllItens} />
            )
        }
    ];
    // ============================= FUNÇÕES =============================

    const handleFilterProjetos = (value) => {
        const newList = listAllProjetos.filter((el) => el.obra_id === value);
        setFiltredProjetos(newList);
    };

    const atualizarCampo = (indiceCampo, campo, valor) => {
        setCampos((prevCampos) => {
            if (campo === 'fornecedor') {
                const novosCampos = [...prevCampos];
                novosCampos[indiceCampo][campo] = parseInt(valor);
                return novosCampos;
            } else {
                const novosCampos = [...prevCampos];
                novosCampos[indiceCampo][campo] = valor;
                return novosCampos;
            }
        });
    };

    const adicionarCampoItem = (indiceCampo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].itens.push({
                item_id: '',
                descricao: '',
                quantidade: 0,
                unidade: '',
                indiceCampoItem: ''
            });
            return novosCampos;
        });
    };

    const removerCampoItem = (indiceCampo, indiceCampoItem) => {
        setItens((prevItens) => {
            const novosItens = [...prevItens];
            const teste = novosItens.find((item) => item.id === indiceCampoItem + 1);
            const filtredItens = novosItens.filter((item) => item !== teste);
            return filtredItens;
        });
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[0].itens.splice(indiceCampoItem, 1);
            return novosCampos;
        });
        if (openCreate) {
            setOpenCreate(false);
        }
    };

    const atualizarCampoItem = (indiceCampo, indiceCampoItem, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].itens[indiceCampoItem][campo] = valor;
            return novosCampos;
        });
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const handleOpenAndCreateCampoItem = (indiceCampo) => {
        setOpenCreate(true);
        adicionarCampoItem(indiceCampo);
    };

    const handleCreate = (indiceCampo) => {
        adicionarCampoItem(indiceCampo);
    };

    const getDependency = async () => {
        const [itens, obras, projetos] = await Promise.all([api.getAllItens(), api.getAllObra(), api.getAllProjeto()]);
        setListAllItens(itens.data.itens);
        setListAllObras(obras.data.obras);
        setListAllProjetos(projetos.data.projetos);
    };

    const getDate = () => {
        const date = new Date();
        let currentDay = String(date.getDate()).padStart(2, '0');
        let currentMonth = String(date.getMonth() + 1).padStart(2, '0');
        let currentYear = date.getFullYear();
        let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[0].emissao = currentDate;
            return novosCampos;
        });
    };

    useEffect(() => {
        getDependency();
        getDate();
    }, []);

    //====== SUBMIT ======
    const handleSubmit = async (e, campo) => {
        e.preventDefault();
        itens.forEach((item) => {
            delete item.id;
        });
        const data = { entrada: campo, itens: itens };

        try {
            await api.createSolicitacao(data.entrada, data.itens);
            setCampos(undefined);
            setCampos([
                {
                    emissao: new Date(),
                    necessidade: '',
                    obra: '',
                    projeto: '',
                    observacao: '',
                    itens: []
                }
            ]);
            setItens([]);
            setOpenCreate(false);
            toast.success('Solicitação cadastrada');
        } catch (error) {
            toast.error(`Erro ao criar solicitação: ${error.message}`);
        }
    };

    return (
        <MainCard title="Nova Solicitação">
            <Grid container spacing={gridSpacing}>
                <Box sx={{ width: '80%', margin: '0 auto' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginLeft: '1em', marginTop: '1em' }}>
                        {campos.map((campo, indiceCampo) => (
                            <Box key={indiceCampo}>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '1em',
                                            width: '100%',
                                            marginBottom: '1em'
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <TextField
                                                type="date"
                                                label="Emissão"
                                                value={campo.emissao}
                                                disabled
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth>
                                            <TextField
                                                type="date"
                                                value={campo.necessidade}
                                                onChange={(e) => atualizarCampo(indiceCampo, 'necessidade', e.target.value)}
                                                label="Necessidade"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                select
                                                value={campo.obra}
                                                label="Obra"
                                                onChange={(e) => {
                                                    atualizarCampo(indiceCampo, 'obra', e.target.value),
                                                        handleFilterProjetos(e.target.value);
                                                }}
                                            >
                                                {listAllObras.map((obra) => (
                                                    <MenuItem key={obra.id} value={obra.id}>
                                                        {obra.descricao}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                select
                                                value={campo.projeto}
                                                label="Projeto"
                                                onChange={(e) => atualizarCampo(indiceCampo, 'projeto', e.target.value)}
                                            >
                                                {filtredProjetos.map((projeto) => (
                                                    <MenuItem key={projeto.id} value={projeto.id}>
                                                        {projeto.descricao}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormControl sx={{ width: '50%' }}>
                                            <TextField
                                                multiline
                                                minRows={3}
                                                placeholder="Observação"
                                                value={campo.observacao}
                                                onChange={(e) => atualizarCampo(indiceCampo, 'observacao', e.target.value)}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box sx={{ width: '100%', marginTop: '2em', borderTop: '2px dashed #eee' }}>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '1em' }}>
                                        <Box>
                                            <Typography variant="h4">Relação de itens</Typography>
                                        </Box>
                                        <Box>
                                            <Tooltip title="Adicionar item">
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    onClick={(e) => handleOpenAndCreateCampoItem(indiceCampo)}
                                                >
                                                    <AddIcon />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>

                                    <Box sx={{ marginTop: '2em', display: 'flex', flexDirection: 'column', height: '300px' }}>
                                        <DataGrid
                                            rows={itens}
                                            columns={columns}
                                            hideFooter={true}
                                            onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                                        />
                                        <Box
                                            sx={{
                                                width: '40%',
                                                marginLeft: 'auto',
                                                marginTop: '1em',
                                                display: 'flex',
                                                justifyContent: 'flex-end'
                                            }}
                                        >
                                            <Tooltip title="Salvar entrada">
                                                <Button variant="contained" color="success" onClick={(e) => handleSubmit(e, campo)}>
                                                    <SaveIcon />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>
                                {campos[0]?.itens.map((item, indiceCampoItem) => (
                                    <ActionButtonsCreate
                                        key={indiceCampoItem}
                                        open={openCreate}
                                        itens={itens}
                                        setItens={setItens}
                                        atualizarCampoItem={atualizarCampoItem}
                                        removerCampoItem={removerCampoItem}
                                        indiceCampoItem={indiceCampoItem}
                                        item={item}
                                        listAllItens={listAllItens}
                                        handleCreate={handleCreate}
                                    />
                                ))}
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default NovaSolicitacao;
