import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const BoxContainer = styled(Box)({
    width: '65%',
    marginTop: '.5em',
    padding: '.5em',
    borderRadius: '5px'
});

export const BoxHeader = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between'
});

export const FormBox = styled(Box)({
    width: '100%'
});

export const FieldBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1em',
    margin: '1em 0 1em 0'
});
