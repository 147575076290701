import { useParams, Link } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, TablePagination } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import FormBaixa from 'components/Estoque/Movimentos/Baixa';

const BaixaManual = () => {
    // ============================= INSTÂNCIAS ==========================
    const api = useApi();
    // ============================= STATES ==============================

    // ============================= FUNÇÕES =============================

    return (
        <MainCard title="Baixa Manual">
            <Grid container spacing={gridSpacing}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <FormBaixa />
                </Box>
            </Grid>
        </MainCard>
    );
};

export default BaixaManual;
