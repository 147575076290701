import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useApi } from 'Service/axios';
import { FormBox } from './style';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const EditarcentroCusto = ({ ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const { id } = useParams();
    const navigate = useNavigate();
    const api = useApi();
    const [submitError, setSubmitError] = useState(null);
    const [centroCusto, setcentroCusto] = useState(null);

    const getCentroCustoById = async (id) => {
        const response = await api.getCentroCustoById(id);
        setcentroCusto(response.data.centro);
    };

    function isAnyFieldEmpty(values) {
        const fieldsToCheck = ['descricao'];

        for (const field of fieldsToCheck) {
            if (values[field].trim() === '') {
                return true;
            }
        }

        return false;
    }

    const handleRegister = async (values) => {
        // monta um objeto apenas com valores !== de ""
        const filteredValues = Object.entries(values).reduce((acc, [key, value]) => {
            if (value !== '') {
                acc[key] = value;
            }
            return acc;
        }, {});

        // monta o payload final
        const centroCusto = filteredValues;
        try {
            const response = await api.editCentroCusto(id, centroCusto);
            toast.success('Editado com sucesso!');
            navigate('/main/centroCusto');
        } catch (error) {
            toast.error(error.response.data.mensagem);
            setSubmitError(error.message);
        }
    };

    const clearValues = (values) => {
        values.descricao = '';
    };

    useEffect(() => {
        getCentroCustoById(id);
    }, []);

    return (
        <>
            {centroCusto !== null && (
                <Formik
                    initialValues={{
                        descricao: centroCusto?.descricao,
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        descricao: Yup.string().required('Descrição é obrigatório')
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                            handleRegister(values);
                        } catch (err) {
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.msg });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                        <form noValidate onSubmit={handleSubmit} {...others} style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.descricao && errors.descricao)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-descricao-register">Descrição</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-descricao-register"
                                        type="text"
                                        value={values.descricao}
                                        name="descricao"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.descricao && errors.descricao && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.descricao}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>
                            {/* 
                            {!submitError && errors.submit ? (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            ) : (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{submitError}</FormHelperText>
                                </Box>
                            )} */}

                            {/* BOTÕES */}
                            <Box sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <Link to={'/main/centroCusto'}>
                                    <Button>Voltar</Button>
                                </Link>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting || isAnyFieldEmpty(values)}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        {isSubmitting || isAnyFieldEmpty(values)
                                            ? 'Descrição do centro de Custo está faltando'
                                            : 'Atualizar centro de Custo'}
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default EditarcentroCusto;
