import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import { useState } from 'react';

export function ActionInput({ allRows, currentRow, qnt, setQnt }) {
    return (
        <TextField
            placeholder="Quantidade a ser liberada"
            type="number"
            name="qnt"
            onChange={(e) => {
                setQnt(e.target.value);
                allRows[currentRow.index].quantidade = e.target.value;
            }}
        />
    );
}
