import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import { useApi } from 'Service/axios';
import { useNavigate, Link } from 'react-router-dom';
import { MainBox, FormBox, TableBox, HeaderBox } from './style';
import CadastroCentroCusto from 'components/CentroCusto/CadastroCentroCusto/index';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';

function CentroCustos() {
    // === INSTÂNCIAS ===
    const api = useApi();
    const navigate = useNavigate();
    // === STATES ===
    const [formOpen, setFormOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [centroCustoId, setcentroCustoId] = useState(null);
    const [centroCusto, setcentroCusto] = useState([]);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 2 },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Deletar Centro">
                        <IconButton onClick={(e) => handleClickOpenDelete(e, params.row.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar Centro">
                        <Link to={`/main/centroCusto/edit/${params.row.id}`}>
                            <IconButton>
                                <EditIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </Box>
            )
        }
    ];
    // === FUNÇÕES ===

    const getAllCentroCusto = async () => {
        try {
            const response = await api.getAllCentroCusto();
            setcentroCusto(response.data.centros);
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleClickOpenDelete = (e, id) => {
        e.preventDefault();
        setOpenDelete(true);
        setcentroCustoId(id);
    };

    const handleCloseDelete = (e) => {
        e.preventDefault();
        setOpenDelete(false);
    };

    const handleCancelDeleteDialog = () => {
        setOpenDelete(false);
    };

    const handleConfirmDeleteDialog = async (centroCusId) => {
        try {
            const deleteCentroCusto = await api.deleteCentroCusto(centroCustoId);
            toast.success('Deletado com sucesso!');
            getAllCentroCusto();
            setOpenDelete(false);
        } catch (error) {
            if (error.response.status === 500) {
                toast.error('Erro. Por favor tente novamente mais tarde.');
            }
            toast.error(error.message);
            setOpenDelete(false);
        }
    };

    useEffect(() => {
        getAllCentroCusto();
    }, []);

    return (
        <MainCard title="Centro de Custos">
            <Grid container spacing={gridSpacing}>
                <MainBox>
                    <Dialog
                        open={openDelete}
                        onClose={handleCloseDelete}
                        aria-labelledby="alert-dialog-title-delete"
                        aria-describedby="alert-dialog-description-delete"
                    >
                        <DialogTitle id="alert-dialog-title-delete">{'Deletar Registro'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description-delete">
                                Tem certeza que deseja excluir o registo ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleConfirmDeleteDialog(centroCustoId)}>Sim</Button>
                            <Button onClick={handleCancelDeleteDialog}>Cancelar</Button>
                        </DialogActions>
                    </Dialog>
                    {formOpen && (
                        <FormBox>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setFormOpen(!formOpen);
                                    getAllCentroCusto();
                                }}
                            >
                                Ver Tabela
                            </Button>
                            <Box sx={{ width: '80%', margin: '2em' }}>
                                <CadastroCentroCusto getAllcentroCusto={getAllCentroCusto} />
                            </Box>
                        </FormBox>
                    )}

                    {!formOpen && (
                        <TableBox sx={{ width: '100%' }}>
                            <HeaderBox>
                                <Button variant="contained" onClick={() => setFormOpen(!formOpen)}>
                                    Cadastrar Centro de Custo
                                </Button>
                            </HeaderBox>

                            <TableContainer
                                component={Paper}
                                style={{
                                    width: '100%',
                                    maxHeight: '600px',
                                    overflowY: 'auto',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor: 'rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.3)'
                                }}
                            >
                                <DataGrid
                                    rows={centroCusto}
                                    columns={columns}
                                    onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                                />
                            </TableContainer>
                        </TableBox>
                    )}
                </MainBox>
            </Grid>
        </MainCard>
    );
}

export default CentroCustos;
