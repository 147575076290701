import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { borderRadius } from '@mui/system';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px'
};

export default function AnalyseModal({ open, setOpen, currentId }) {
    const handleClose = () => setOpen(false);
    const [requestData, setRequestData] = useState([]);

    const api = useApi();

    async function getNv2() {
        try {
            const { data } = await api.getNv2Separacao(currentId);

            setRequestData(data?.separacao_itens);
        } catch (error) {
            console.log('🚀 ~ getNv2 ~ error:', error);
        }
    }

    useEffect(() => {
        getNv2();
    }, [currentId]);

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h2 style={{ textAlign: 'center' }}>Análise de itens separados</h2>
                    {requestData?.length === 0 ? (
                        <h3 style={{ textAlign: 'center' }}>sem itens separados</h3>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Descrição</TableCell>
                                        <TableCell align="center">Quantidade Solicitada</TableCell>
                                        <TableCell align="center">Quantidade Separada</TableCell>
                                        {/* <TableCell align="center">QTD Restante</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {requestData?.map((row) => (
                                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center">{row?.item?.descricao}</TableCell>
                                            <TableCell align="center">{row?.solicitacao_iten?.quantidade}</TableCell>
                                            <TableCell align="center">{row?.quantidade_separado}</TableCell>
                                            {/* <TableCell align="center">
                                                {row?.solicitacao_iten?.quantidade - row?.quantidade_separado}
                                            </TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>
            </Modal>
        </div>
    );
}
